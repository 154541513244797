import React from 'react'
import Drawer from '@mui/material/Drawer';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { mainNavbarItems } from './consts/navbarItems';
import { navbarStyles } from './styles';
import { useNavigate } from "react-router-dom";
import { OpenContext } from './OpenContext';
import NavItem from './NavItem';

const Navbar = () => {
    const navigate = useNavigate();
    const {navbarOpen, setNavbarOpen} = React.useContext(OpenContext)

    const toggleDrawer = (open) => (event) => {

      if ((event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) || event.defaultPrevented==true) {
        return;
      }
      setNavbarOpen(false);
    };
  
    return (
      
      <Drawer
        sx={{
          width: navbarStyles.drawer.width,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: navbarStyles.drawer.width,
            boxSizing: navbarStyles.drawer['& .MuiDrawer-paper'].boxSizing,
            backgroundColor: navbarStyles.drawer['& .MuiDrawer-paper'].backgroundColor,
          },
        }}
        role="presentation"
        //variant="persistent"
        anchor="left"
        open={navbarOpen}
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >


        <Toolbar />
        <Divider />
        <List>
        {mainNavbarItems.map((item, index) => (
          <NavItem key={`${item.label}-${index}`} item={item} margin={null}/>
          ))}
        </List>
      </Drawer>
    );
};

export default Navbar