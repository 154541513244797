import CustomDataGrid from '../../../components/DataGrid/DataGrid';
import { DeleteButton } from '../../../components/DataGrid/DeleteButton';
import { EditButton } from '../../../components/DataGrid/EditButton';
import { useGetLocationsQuery, useDeleteLocationByIdMutation } from '../../../stores/services/LocationService';
// Internal
export default function LocationGrid() {
    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 0,
            align: 'center'
        },
        {
            field: 'home',
            headerName: 'Heim',
            flex: 0,
            align: 'left',
            type: 'boolean',
        },
        {
            field: 'district',
            width: 400,
            headerName: 'Ort',
            align: 'left',
        },
        {
            field: 'street',
            headerName: 'Straße',
            flex: 1,
            align: 'left'
        },
        {
            field: 'actions',
            type: 'actions',
            align: 'left',
            getActions: (params) => [
                    <DeleteButton params={params.row} ></DeleteButton>,
                    <EditButton params={params.row}></EditButton>
            ]
        },
    ];


    return (
        <>
            <CustomDataGrid
                    columns = {columns}
                    getQuery={useGetLocationsQuery}
                    deleteQuery={useDeleteLocationByIdMutation}
                />
      </>
    )
}
