import * as React from 'react';
import { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { useRegisterMutation } from '../../stores/services/AuthService';


import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import FormPaper from '../../components/FormFields/FormPaper';
import { FormInputText } from '../../components/FormFields/FormInputText';
import { setFormError, createOptions } from '../../services/ApiService';


const defaultValues = {
    username:"",
    email: "",
    password: "",
    password2: "",
  };


  export default function RegisterPage(){
    const { loading, userInfo, error, success } = useSelector(
      (state) => state.auth
    )
    const dispatch = useDispatch()
    const form = useForm({ defaultValues: defaultValues });
    const { handleSubmit, reset, control, setValue, watch, getValues, setError, clearErrors, formState, getFieldState } = form;
    const navigate = useNavigate();
    const [registerTrigger, registerResult] = useRegisterMutation()

    const onSubmit = async (data, goback) => {
      if (data.password !== data.password2) {
        setError('password',{ type: 'custom', message: 'Passwörter nicht identisch' })
        setError('password2',{ type: 'custom', message: 'Passwörter nicht identisch' })
        return
      }
      // transform email string to lowercase to avoid case sensitivity issues in login
      data.email = data.email.toLowerCase()
      const query = {
        data : data
      }
      registerTrigger(query)
 
    };
  
    useEffect(() => {
      if (registerResult.isSuccess) {
        clearErrors()
        navigate('/login')
      }
      
      if (registerResult?.error) {
        const formErrors = registerResult.error.formErrors



         // Loop through the API errors and set them in React Hook Form
         Object.keys(formErrors).forEach((fieldName) => {
          setError(fieldName, {
            type: 'manual',
            message: formErrors[fieldName],
          });
        });
      }

   
 

    }, [registerResult.isError, registerResult.isSuccess]);


    return (
      <FormPaper>
        <FormInputText name="username" control={control} label="Name" />
        <FormInputText name="email" control={control} label="Email" />
        <FormInputText name="password" control={control} label="Passwort" type="password"/>
        <FormInputText name="password2" control={control} label="Passwort" type="password"/>
        <Button onClick={handleSubmit((data) => onSubmit(data,true))} variant={"contained"}>
          {" "}
          {'Registrieren'}
        </Button>    


      </FormPaper>
   
    )
}

