
import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from './ApiBase'


export const reportApi = createApi({
    reducerPath: 'reportApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['reports'],
    endpoints: (builder) => ({
        getReports: builder.query({
          query: (query) =>({
            url: `api/reports/reports`,
            method: 'GET',
            params: query?.params
        })
        }),
        getPdf: builder.query({
            query: (query) =>({
              url: `api/reports/pdf/${query.id}`,
              method: 'GET',
              params: query?.params
          })
          }),
        getEditUrl: builder.query({
            query: (query) =>({
              url: `api/reports/edit/${query.id}`,
              method: 'GET',
              params: query?.params
          })
          }),
    })
})
  


export const { 
    useGetReportsQuery,
    useGetPdfQuery,
    useLazyGetPdfQuery,
    useLazyGetEditUrlQuery,
    useGetEditUrlQuery,
} = reportApi

