import { navbarStyles } from './styles';
import {styled} from '@mui/material/styles';
import { Outlet } from "react-router-dom";
import { OpenContext } from './OpenContext';
import {  Toolbar } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { padding } from '@mui/system';


const StyledMain = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  (
    { theme, open }) => ({

      // flexGrow: 1,
      // padding: theme.spacing(3),
      // transition: theme.transitions.create('margin', {
      //   easing: theme.transitions.easing.sharp,
      //   duration: theme.transitions.duration.leavingScreen,
      
      // }),
      // marginLeft: `-${navbarStyles.drawer.width}px`,
      // ...(open && {
      //   transition: theme.transitions.create('margin', {
      //     easing: theme.transitions.easing.easeOut,
      //     duration: theme.transitions.duration.enteringScreen,
      //   }),
      //   marginLeft: 0,
      // }),
    }),
);

const Main = ()=>{
    const {navbarOpen, setNavbarOpen} = React.useContext(OpenContext)
    return (
      <Box 
        sx={{

          }}
        >
     
          <Toolbar></Toolbar>
            <Outlet></Outlet>
    
      </Box>
    )
}

export default Main