// authActions.js
import { createAsyncThunk } from '@reduxjs/toolkit'

 export const addTaskProgressAction = createAsyncThunk(
  'task/progressAdd',
  async (name, { getState }) => {
    return name + getState().counter.value;
  }
 )


