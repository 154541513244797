import * as React from 'react';
import Appbar from './Appbar';
import Navbar from './Navbar';
import Main from './Main';
import Box  from '@mui/material/Box';
import { OpenContextProvider } from './OpenContext';






export default function PageLayout({children}) {

  return (
    <Box padding={5} >
      <OpenContextProvider>
        <Appbar></Appbar>
    
        <Navbar></Navbar>
      
        <Main></Main>
      </OpenContextProvider>
    </Box>

  );
}