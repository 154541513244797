

import Box from '@mui/material/Box';
// Internal
import LocationGrid from './Grid';


export default function LocationsPage(){


    return (
        <>
            <Box sx={{ height: 400, width: '100%' }}>      
                <LocationGrid ></LocationGrid>
            </Box>
        </>


    )
}

