export function getChildValue (data, key) {
    if (key==null || data==null){
        return null
    }
    if (key in data) { // push the value to the result array only if key exists
        return data[key]
    }
    else {
        return null
    }
  };