import CustomDataGrid from '../../../components/DataGrid/DataGrid';
import {  useGridRootProps } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { getChildValue } from '../../../utilities/DataGridHelper';
import { TaskButton } from '../../../components/DataGrid/TaskButton';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import { useGetTeamsQuery, useDeleteTeamByIdMutation } from '../../../stores/services/TeamService';
import { DeleteButton } from '../../../components/DataGrid/DeleteButton';
import { EditButton } from '../../../components/DataGrid/EditButton';
import { ActionCell } from '../../../components/DataGrid/GridColumns';
// Internal
export default function TeamGrid() {
    
    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 0,
            align: 'center'
        },
        {
            field: 'home',
            headerName: 'Heim',
            flex: 0,
            align: 'left',
            type: 'boolean',
            valueGetter: (params) => {
                return getChildValue(params.row.club,'home');
            }
        },
        {
            field: 'club',
            width: 200,
            headerName: 'Verein',
            align: 'left',
       
            renderCell: (params) => {
                let clubid = getChildValue(params.row.club,'id')
                let clubname = getChildValue(params.row.club,'name')
                let link = "/clubs/" + clubid
                return (<Link to={link}  params={{ id: clubid }}>{clubname}</Link>)
            }
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            align: 'left'
        },
        {
            field: 'actions',
            type: 'actions',
            align: 'left',
            renderCell: (params) =>{
                
              
                return (
                <ActionCell row={params.row} table='teamTable'>  
                    
                    <DeleteButton params={params.row} key = {`delete_${params.id}`} ></DeleteButton>
                    <EditButton params={params.row} key = {`edit_${params.id}`}></EditButton>
                </ActionCell>
            )}
        },
    ];

    const icon = <CloudSyncIcon></CloudSyncIcon>
    const syncGcalButton = (key) => {
    return (<TaskButton 
        key = {key}
        dialogtitle = 'Synchronisation starten?' 
        url='teams' 
        task='gcal' icon = {icon} title = 'Google' table='teamTable'> 
    </TaskButton>)
    }

    //const icon = <CloudSyncIcon></CloudSyncIcon>
    const syncBfvButton = (key) => {
    return (<TaskButton 
        key = {key}
        dialogtitle = 'Synchronisation starten?' 
        url='teams' 
        task='bfv' icon = {icon} title = 'Bfv' table='teamTable'> 
    </TaskButton>)
    }

    return (
        <>
            <CustomDataGrid
                    columns = {columns}
                    buttons={[syncGcalButton, syncBfvButton]}
                    getQuery={useGetTeamsQuery}
                    deleteQuery={useDeleteTeamByIdMutation}
                />
      </>
    )
}
