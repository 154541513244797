import React, { useState } from "react";

export const OpenContext = React.createContext();
export const OpenContextProvider = ({ children }) => {
    const [navbarOpen, setNavbarOpen] = useState(false);

    return (
        <OpenContext.Provider value={{ navbarOpen, setNavbarOpen }}>
            {children}
        </OpenContext.Provider>
    );
};