import {  useCallback, useRef } from 'react';
import { useState, useEffect } from 'react';



import { DataGrid } from '@mui/x-data-grid';

import CustomToolbarGrid from '../../components/DataGrid/CustomToolBar';
import { DialogContentText } from '@mui/material';

import { ConfirmDialog, confirmDialog }  from '../Dialog/ConfirmDialog';



// Internal

function CustomDataGrid({ columns, buttons, getQuery, deleteQuery, trigger=null, getArgs=null, sort=null  }) {
    const [selectionModel, setSelectionModel] = useState([]);
    const [rows , setRows] = useState()
    let getQueryStore = null
    if (getArgs){    
        
        getQueryStore = getQuery(getArgs);
    } else {
         getQueryStore = getQuery();
    }
 
    
    
    const [loading, setLoading] = useState(getQueryStore.isLoading);
    const [deleteTrigger, deleteQueryStore] = deleteQuery(11)

    const rowsRef = useRef({});
    rowsRef.current = rows;
    
    const [rowDeleteDialogOpen, setRowDeleteDialogOpen] = useState(true);
    const rowDeleteDialogOpenRef = useRef();
    rowDeleteDialogOpenRef.current = rowDeleteDialogOpen
    const setRowDeleteDialogOpenRef = useRef();
    setRowDeleteDialogOpenRef.current = setRowDeleteDialogOpen

    useEffect(()=> {
        if (trigger!=null){
            if (getArgs!=null){
                getQueryStore.refetch()
            }
        }
      }, [trigger]) 



    useEffect(()=> {
        if (getQueryStore.isSuccess==true ){
          setRows(getQueryStore.data?.data? getQueryStore.data.data:getQueryStore.data)
        }
      }, [getQueryStore]) 




    const deleteRow = useCallback(
        (params) => () => {
            
            const onSubmit = () => { 
                confirmDeleteRow(params) 
            }
            const title = "Willst du die Zeile wirklich löschen?"
            const content = <DialogContentText>{"Eintrag: " + params.id + " löschen?"}</DialogContentText>
            confirmDialog(title,content,onSubmit)
        }
    );

    const confirmDeleteRow = (params) => {
            setRowDeleteDialogOpen(false)
            setLoading(true)
            deleteTrigger(params.id)
            setLoading(false)
        };

    const deleteRows = useCallback(
            () => () => {
                const onSubmit = () => { 
                    confirmDeleteRows() 
                }
                const title = "Willst du die Zeilen wirklich löschen?"
                const content = <DialogContentText>{selectionModel.length + " Einträge löschen?"}</DialogContentText>
                confirmDialog(title,content,onSubmit)
            }
        );

    
    const confirmDeleteRows = () => {
        setRowDeleteDialogOpen(true)
        setLoading(true)
        selectionModel.forEach((id,i) =>{
            deleteTrigger(id)
        })
        setLoading(false)
    };
    
    return (
        <>        
            <DataGrid
                initialState={{
                    sorting: {
                    sortModel: sort,
                    },
                }}
                autoHeight={true}
                loading={(rows===undefined)?true:false}
                rows={rows?rows:[]}
                columns={columns}
                checkboxSelection
                disableSelectionOnClick
                onSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
                components={{
                    Toolbar: CustomToolbarGrid,
                }}
                componentsProps={
                    {
                        root: {
                            deleteRow: deleteRow,
                            deleteRows: deleteRows,
                        },
                        toolbar: {
                            selectionModel: selectionModel,
                            setSelectionModel: setSelectionModel,
                            rows: rows,
                            columns: columns,
                            buttons: buttons
                        }
                    }
                }

            >

            </DataGrid>
            <ConfirmDialog/>
        </>





    )
}

export default CustomDataGrid