import * as React from 'react';
import { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { userLogin } from '../../stores/actions/authActions';
import { openSnackbar } from '../../stores/slices/notifySlice';


import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useLoginMutation } from '../../stores/services/AuthService';



import FormPaper from '../../components/FormFields/FormPaper';
import { FormInputText } from '../../components/FormFields/FormInputText';


const defaultValues = {
  email: "",
  password: "",
};


const LoginPage = () => {
  const [open, setOpen] = React.useState(false);
  const { loading, userInfo, accessToken, error } = useSelector(
    (state) => state.auth
  )
  const dispatch = useDispatch()
  const methods = useForm({ defaultValues: defaultValues });
  const { handleSubmit, reset, control, setValue, watch, getValues, setError, clearErrors, formState, getFieldState } = methods;
  const navigate = useNavigate();
  const [loginTrigger, loginResult] = useLoginMutation()

  const onSubmit = async (data, goback) => {

    dispatch(userLogin(data))
  };

  useEffect(() => {
    if (accessToken) {
      const snackbar = {
        severity:'success',
        vertical:'top',
        horizontal:'center',
        message:'Erfolgreich eingeloggt!'
      }
      dispatch(openSnackbar(snackbar))
      navigate(-1)
    }
    if (error){
      const snackbar = {
        severity:'warning',
        vertical:'top',
        horizontal:'center',
        message:'Name oder Passwort nicht korrekt!'
      }
      dispatch(openSnackbar(snackbar))
    }
  }, [navigate, accessToken, error])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <FormPaper>
      <FormInputText name="email" control={control} label="Email" />
      <FormInputText name="password" control={control} label="Passwort" type="password" />
      <Link align='left'
        component="button"
        underline="hover"
        variant="body2"
        onClick={() => {
          navigate("/register");
        }}
      >
        Noch nicht registriert?
      </Link>
      <Button onClick={handleSubmit((data) => onSubmit(data, true))} variant={"contained"}>
        {" "}
        {"Login"}
      </Button>
    </FormPaper>
  )
}

export default LoginPage