import { DeleteButton } from '../../components/DataGrid/DeleteButton';
import { EditButton } from '../../components/DataGrid/EditButton';
import CircularProgress from '@mui/material/CircularProgress';
import CircularProgressWithLabel from '../../components/CircualrProgressWithLabel';
import { SuccessDialog, successDialog } from '../../components/Dialog/SuccessDialog';
import { ErrorDialog, errorDialog } from '../../components/Dialog/ErrorDialog';
import  Button  from '@mui/material/Button';
import  Typography  from '@mui/material/Typography';
import { useGridApiContext, useGridRootProps } from '@mui/x-data-grid';
import { Children, useEffect, useState } from 'react';
import store from "../../stores/store";
import { useSelector } from "react-redux";
import { clearProgress } from "../../stores/slices/taskSlice";

export const ActionCell = (props) => {
    const gridProps = useGridRootProps();
    const ident = props.table
    const task = props.task
    const progress = useSelector(state => state.task.progress[ident]?.[props.row.id])
    const [currentProgress, setCurrentProgress] = useState(progress)
    useEffect(() => {
        if (progress){
            setCurrentProgress(progress)
        }
    }, [progress])
    

    const onSubmit = () => {
        const payload =  {
            id:props.row.id,
            ident:ident,
            }
        store.dispatch(clearProgress(payload))

    }

    const renderProgressField = (param) => {
        switch (param) {
            case 0:
                return (
                    <>
                       <CircularProgress />
                    </>
                )
            case 1:
                return (
                    <>
                        <CircularProgressWithLabel value={progress.percentage} />
                    </>
                )
            case 2:
                return (
                    <>
                    <Button variant='contained' color='error' 
                        onClick={() => errorDialog('Task fehlerhaft', <span dangerouslySetInnerHTML={{__html: progress.msg}} />, onSubmit)}
                        > 
                        Fehler 
                    </Button>
                    <ErrorDialog></ErrorDialog>
                </>
                )
            case 3:
                    return (
                        <>
                            <Button variant='contained' onClick={() => successDialog('Task erfolgreich abgeschlossen',  <span dangerouslySetInnerHTML={{__html: progress.msg}} /> , onSubmit)}> Erfolg </Button>
                            <SuccessDialog></SuccessDialog>
                        </>
                    )
            default:
                return (
                    <>
                        {props.children}   
              
                    </>
                )
        }
    }

    return (
        <>  
            {renderProgressField(progress?.status)}
        </>
    )
  };


