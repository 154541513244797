import { useForm } from "react-hook-form";
import {  useEffect, useRef } from 'react';
import { useGetTeamsQuery } from '../../stores/services/TeamService';
import { FormInputCheckbox } from '../../components/FormFields/FormInputCheckbox';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';



const SelectionTeam = ({teamIdsInit = [], teamIds, queryFinished}) => {
    //========================
    // Get Teams for Selection
    //========================
    let teams = []
    const getTeamsQueryStore = useGetTeamsQuery({home:true})
    const formTeams = useForm();
    const teamsByTypeRef = useRef([]);
    useEffect(() => {
        if (getTeamsQueryStore.isSuccess) {
            const data = getTeamsQueryStore.data.data
            const defaultValues = {}
            const teamsByTypeTemp = {}
            data.map(
                (team)=>{
                    defaultValues[String(team.id)] = teamIdsInit.includes(team.id)
                    let type = 'Sonstiges'
                    if (team?.teamgroup?.teamtype?.name){
                        type = team?.teamgroup?.teamtype?.name
                    }
                    let types = teamsByTypeTemp[type]
                    if (types){
                        types.push({'id':team.id, 'name':team.name});
                    } else {
                        types = [{'id':team.id, 'name':team.name}]
                    }
                    teamsByTypeTemp[type] = types
            })  
            formTeams.reset(defaultValues)
            teams = data
            teamsByTypeRef.current = teamsByTypeTemp
            queryFinished(true)
  
        }
      }, [getTeamsQueryStore]);

      const watchTeams= formTeams.watch();
      useEffect(() => {
        const teams = []
        const values = formTeams.getValues()
        for (var id in values){
            if (values[id]){
                teams.push(parseInt(id))
            }
        }
        teamIds.current = teams
      }, [watchTeams]);


    return (
        <>
 
            <Stack  direction="row"   
                    justifyContent="center"
                    alignItems="center" 
                    spacing={2}>
                    {
                    !teams?
                    <>
                        <CircularProgress />
                    </>
                    :
                    <>
                        {Object.keys(teamsByTypeRef.current).map((key,index)=>{
            
                            const teams = teamsByTypeRef.current[key]
                            return (
                                    <Stack 
                                        key = {key}  
                                        alignItems="top"
                                        >
                                        <Typography key = {key + '1'} variant="h4" gutterBottom>
                                            {key}
                                        </Typography>
                                    
                                        {teams.map((team)=>{
                                            return (<FormInputCheckbox key={String(team.id)}  name={String(team.id)} control={formTeams.control} label={team.name} />)
                                            })
                                        }
                                    </Stack> 
                                )
                            })
                        }
                    </>
                    }

    
            </Stack>


 
        

        </>
    )
}

export default SelectionTeam