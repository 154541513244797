import React from "react";
import { useForm, Controller } from "react-hook-form";
//import TextField from "@material-ui/core/TextField";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

export const FormInputCheckbox = ({ name, control, label }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <>
        <FormControlLabel  control={
          
          <Checkbox
            
            helpertext={error ? error.message : null}
            //helperText = 'helper text'
            size="small"
            //error={!!error}
            onChange={onChange}
            checked={value}
            value={value}
            //fullWidth
            label={label}
            variant="outlined"
          />
      } label={label} /></>
      
      )}
    />
  );
};