
import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from './ApiBase'

export const clubApi = createApi({
    reducerPath: 'clubApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Club'],
    endpoints: (builder) => ({
        getClubs: builder.query({
            query: () =>({
                url: 'api/clubs/',
                method: 'GET',
            }),
            providesTags: () => ['Club']
        }),
        getClubById: builder.query({
            query: (id) =>({
                url: `api/clubs/${id}`,
                method: 'GET',
            }),
            providesTags: () => ['Club']
        }),
        getClubByName: builder.query({
            query: (name) =>({
                url: `api/clubs/${name}`,
                method: 'GET',
            }),
            providesTags: () => ['Club']
        }),
        editClubById: builder.mutation({
            query:(data) => ({
                url: `api/clubs/${data.id}`,
                method: 'post',
                body:data
            }),
            invalidatesTags: ['Club'],
        }),
        updateClubById: builder.mutation({
            query:(data) => ({
                url: `api/clubs/${data.id}`,
                method: 'put',
                body:data
            }),
            invalidatesTags: ['Club'],
        }),
        deleteClubById: builder.mutation({
            query:(id) => ({
                url: `api/clubs/${id}`,
                method: 'delete',
            }),
            invalidatesTags: ['Club'],
        })

    })
})
  


export const { 
    useGetClubsQuery, 
    useGetClubByIdQuery, 
    useGetClubByNameQuery,
    useEditClubByIdMutation,
    useUpdateClubByIdMutation,
    useDeleteClubByIdMutation
} = clubApi