import { createListenerMiddleware } from '@reduxjs/toolkit';
import { isRejected, isFulfilled } from '@reduxjs/toolkit'
import { useParams, useNavigate } from 'react-router-dom';
import { logout } from '../slices/authSlice';
import { openSnackbar } from '../slices/notifySlice';


import router from '../../components/Navbar/consts/routeItems';
// Create the middleware instance and methods
const listenerMiddleware = createListenerMiddleware();

// Add one or more listener entries that look for specific actions.
// They may contain any sync or async logic, similar to thunks.
listenerMiddleware.startListening(

  {
    actionCreator:  logout, // Is fired if logout was requested
    effect: async (action, listenerApi) => {
      const snackbar = {
        severity:'success',
        vertical:'top',
        horizontal:'center',
        message:'Erfolgreich ausgeloggt!'
      }
      listenerApi.dispatch(openSnackbar(snackbar))
      router.navigate('/')
    }
  }

  
  );

  listenerMiddleware.startListening(
    {
      predicate: (action, currState, prevState) => isRejected(action),
      effect: async (action, listenerApi) => {

      },
    },  
    
    );

    listenerMiddleware.startListening(
      {
        predicate: (action, currState, prevState) => true,
        effect: async (action, listenerApi) => {

        },
      },  
      
      );

export default listenerMiddleware
