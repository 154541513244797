import PeopleIcon from '@mui/icons-material/People';
import LoginIcon from '@mui/icons-material/Login';

export const profileItems = [
    {
        id: 0,
        icon: <PeopleIcon />,
        label: 'Profile',
        route: 'user/ben',
    },
    {
        id: 1,
        icon: <LoginIcon />,
        label: 'Login',
        route: 'login',
    },


    
]