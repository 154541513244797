import React from "react";
import { useForm, Controller } from "react-hook-form";
//import TextField from "@material-ui/core/TextField";
import  TextField  from "@mui/material/TextField";

export const FormInputText = ( props) => {
  
  return (
    <Controller
      name={props.name}
      control={props.control}  
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <TextField
          helperText={error ? error.message : null}
          
          //helperText = 'helper text'
          size="small"
          error={!!error}
          onChange={onChange}
          value={value?value:""}
          fullWidth
          variant="outlined"
          {...props}
        />
      )}
    />
  );
};