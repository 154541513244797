import * as React from 'react';
import { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useParams, useNavigate,  } from 'react-router-dom';

//import TextField from "@material-ui/core/TextField";
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import FormPaper from '../../../components/FormFields/FormPaper';
import { FormInputText } from '../../../components/FormFields/FormInputText';
import { FormInputDropdown } from '../../../components/FormFields/FormInputDropDown';
import { FormInputCheckbox } from '../../../components/FormFields/FormInputCheckbox';
import { useGetLocationByIdQuery, useAddLocationMutation, useEditLocationByIdMutation, useLazyGetLocationsByTypeQuery } from '../../../stores/services/LocationService';
import { useGetEventtypesQuery } from '../../../stores/services/EventService';
import { useGetGcalsQuery } from '../../../stores/services/GcalService';
import { setFormError} from '../../../stores/services/ApiBase';
import SubmitButton from '../../../components/DataGrid/SubmitButton';
import ColorPicker , { useColorPicker } from 'react-best-gradient-color-picker'


const defaultValues = {
  'city' : null,
  'country' : null,
  'district' : null,
  'postalcode' : null,
  'street' : null,
  'housenumber' : null,
  'home':false,
  'gcal': null,
  'color':'#ffffff',
  'schedulertext':null
};


export default function LocationEditPage() {
  const params = useParams();
  const form = useForm({ defaultValues: defaultValues });
  const [color, setColor] = useState();
  const { handleSubmit, reset, control, setValue, watch, getValues, setError, clearErrors, formState, getFieldState } = form;
  const navigate = useNavigate();
  const [goNext, setGoNext] = useState(false);
  const [triggerUpdate, resultUpdate] = useEditLocationByIdMutation()
  const [triggerAdd, resultAdd] = useAddLocationMutation()
  const { data:instance , getError, isLoading } = useGetLocationByIdQuery(params.id,{skip: params.id=='new'});
  const { valueToHex } = useColorPicker(color, setColor);
 
  useEffect(() => {
    (async () => {
      if (instance){
        reset(instance)
        setColor(instance?.color)
      }
  
    })();
  }, [instance]);

  useEffect(() => {
    (async () => {
      console.log(valueToHex(color))
      setValue('color', valueToHex())
    })();
  }, [color]);


  return (
    <>
      <FormPaper>
        <>
          {isLoading === true  ?
            <CircularProgress />
            :
            <>
              <FormInputText name="district" control={control} label="Ort" />
              <FormInputText name="country" control={control} label="Land" />
              <FormInputText name="city" control={control} label="Oberort" />
              <FormInputText name="postalcode" control={control} label="Postleitzahl" />
              <FormInputText name="street" control={control} label="Straße" />
              <FormInputText name="housenumber" control={control} label="Hausnummer" />
              <FormInputText name="schedulertext" control={control} label="Reporttext" />
              <FormInputCheckbox name="home" control={control} label="Heim" />
              <FormInputDropdown name="gcal" control={control} label="Google Kalender" optionsQuery={useGetGcalsQuery} optionKey='id' optionValue='summary' />
              <ColorPicker value={color} onChange={setColor} />
              <FormInputDropdown
                name="eventtypes"
                control={control}
                label="Eventtypen"
                optionsQuery={useGetEventtypesQuery} 
                optionKey='id' 
                optionValue='name' 
                multiple={true}
              />

            <SubmitButton
                form = {form}
                data={instance}
                queryAdd={useAddLocationMutation}
                queryEdit={useEditLocationByIdMutation}
                goback={true}
              >
              </SubmitButton>
              <Button onClick={() => reset()} variant={"outlined"}>
                {" "}
                Reset{" "}
              </Button>
            </>
          }
        </>
      </FormPaper>

    </>
  );
}