
import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from './ApiBase'

export const teamApi = createApi({
    reducerPath: 'teamApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['team'],
    endpoints: (builder) => ({
        getTeams: builder.query({
            query: (params=null) =>({
                //url: params=null?'api/teams/': `api/teams/?${new URLSearchParams(params || {}).toString()}` ,
                url: 'api/teams/',
                method: 'GET',
                params: params,
            }),
            providesTags: () => ['team']
        }),
        getTeamsHome: builder.query({
            query: () =>({
                url: 'api/teams/home',
                method: 'GET',
            }),
            providesTags: () => ['team']
        }),
        getTeamById: builder.query({
            query: (id) =>({
                url: `api/teams/${id}`,
                method: 'GET',
            }),
            providesTags: () => ['team']
        }),
        getTeamByName: builder.query({
            query: (name) =>({
                url: `api/teams/${name}`,
                method: 'GET',
            }),
            providesTags: () => ['team']
        }),
        addTeamById: builder.mutation({
            query:(data) => ({
                url: `api/teams/`,
                method: 'post',
                body:data
            }),
            invalidatesTags: ['team'],
        }),
        editTeamById: builder.mutation({
            query:(data) => ({
                url: `api/teams/${data.id}`,
                method: 'put',
                body:data
            }),
            invalidatesTags: ['team'],
        }),
        deleteTeamById: builder.mutation({
            query:(id) => ({
                url: `api/teams/${id}`,
                method: 'delete',
            }),
            invalidatesTags: ['team'],
        }),
        getTeamtypes: builder.query({
            query: () =>({
                url: 'api/teams/types/',
                method: 'GET',
            }),
            providesTags: () => ['teamtype']
        }),
        getTeamtypeById: builder.query({
            query: (id) =>({
                url: `api/teams/types/${id}`,
                method: 'GET',
            }),
            providesTags: () => ['teamtype']
        }),
        getTeamtypeByName: builder.query({
            query: (name) =>({
                url: `api/teams/types/${name}`,
                method: 'GET',
            }),
            providesTags: () => ['teamtype']
        }),
        addTeamtypeById: builder.mutation({
            query:(data) => ({
                url: `api/teams/types/`,
                method: 'post',
                body:data
            }),
            invalidatesTags: ['teamtype'],
        }),
        editTeamtypeById: builder.mutation({
            query:(data) => ({
                url: `api/teams/types/${data.id}`,
                method: 'put',
                body:data
            }),
            invalidatesTags: ['teamtype'],
        }),
        deleteTeamtypeById: builder.mutation({
            query:(id) => ({
                url: `api/teams/types/${id}`,
                method: 'delete',
            }),
            invalidatesTags: ['teamtype'],
        }),
        getTeamgroups: builder.query({
            query: () =>({
                url: 'api/teams/groups/',
                method: 'GET',
            }),
            providesTags: () => ['teamgroup']
        }),
        getTeamgroupById: builder.query({
            query: (id) =>({
                url: `api/teams/groups/${id}`,
                method: 'GET',
            }),
            providesTags: () => ['teamgroup']
        }),
        getTeamgroupByName: builder.query({
            query: (name) =>({
                url: `api/teams/groups/${name}`,
                method: 'GET',
            }),
            providesTags: () => ['teamgroup']
        }),
        addTeamgroupById: builder.mutation({
            query:(data) => ({
                url: `api/teams/groups/`,
                method: 'post',
                body:data
            }),
            invalidatesTags: ['teamgroup'],
        }),
        editTeamgroupById: builder.mutation({
            query:(data) => ({
                url: `api/teams/groups/${data.id}`,
                method: 'put',
                body:data
            }),
            invalidatesTags: ['teamgroup'],
        }),
        deleteTeamgroupById: builder.mutation({
            query:(id) => ({
                url: `api/teams/groups/${id}`,
                method: 'delete',
            }),
            invalidatesTags: ['teamgroup'],
        })

    })
})
  


export const { 
    useGetTeamsQuery, 
    useLazyGetTeamsQuery,
    useGetTeamsHomeQuery, 
    useGetTeamByIdQuery, 
    useGetTeamByNameQuery,
    useEditTeamByIdMutation,
    useAddTeamByIdMutation,
    useDeleteTeamByIdMutation,
    useGetTeamgroupsQuery, 
    useGetTeamgroupByIdQuery, 
    useGetTeamgroupByNameQuery,
    useEditTeamgroupByIdMutation,
    useAddTeamgroupByIdMutation,
    useDeleteTeamgroupByIdMutation,
    useGetTeamtypesQuery, 
    useGetTeamtypeByIdQuery, 
    useGetTeamtypeByNameQuery,
    useAddTeamtypeByIdMutation,
    useEditTeamtypeByIdMutation,
    useDeleteTeamtypeByIdMutation
} = teamApi