
import { createSlice } from '@reduxjs/toolkit'
import  {openMessageboxAction} from '../actions/messageActions'
 

const initialState = {
    open: false,
    severity:null,
    vertical:'bottom',
    horizontal:'left',
    message:'Meldung',
    description:'Description'
}

const messageSlice = createSlice({
    name: 'message',
    initialState,
    reducers: {        
        openMessagebox: (state , {payload}) => {
            state.severity = payload.severity?payload.severity:initialState.severity
            state.vertical = payload.vertical?payload.vertical:initialState.vertical
            state.horizontal = payload.horizontal?payload.horizontal:'left'
            state.message = payload.message? payload.message : ""
            state.description = payload.description? payload.description : ""
            state.open = true
        },
        closeMessagebox: (state) => {
            state.open = false
        },

    },
    extraReducers: {
        [openMessageboxAction.pending]: (state) => {

        },
        [openMessageboxAction.fulfilled]: (state, { payload }) => {
            console.log(state)
            state.open = true
        },
        [openMessageboxAction.rejected]: (state, { payload }) => {
    
        }

    },
})
export default messageSlice.reducer

export const { openMessagebox, closeMessagebox } = messageSlice.actions