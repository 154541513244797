import * as React from 'react';
import { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from 'react-router-dom';

//import TextField from "@material-ui/core/TextField";
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import FormPaper from '../../../components/FormFields/FormPaper';
import { FormInputText } from '../../../components/FormFields/FormInputText';
import { setFormError} from '../../../stores/services/ApiBase';
import { useGetTeamtypeByIdQuery, useAddTeamtypeByIdMutation, useEditTeamtypeByIdMutation } from '../../../stores/services/TeamService';


const defaultValues = {
  id: "",
  name: "",
  comment: "",
};


export default function TeamtypeEditPage() {
  const params = useParams();
  const methods = useForm({ defaultValues: defaultValues });
  const { handleSubmit, reset, control, setValue, watch, getValues, setError, clearErrors, formState, getFieldState } = methods;
  const navigate = useNavigate();
  const [goNext, setGoNext] = useState(false);
  const [triggerUpdate, resultUpdate] = useEditTeamtypeByIdMutation()
  const [triggerAdd, resultAdd] = useAddTeamtypeByIdMutation()
  const { data:instance , getError, isLoading } = useGetTeamtypeByIdQuery(params.id,{skip: params.id=='new'});



  const onSubmit = async (data, goback) => {
    setGoNext(goback)
    const d = {}
    Object.entries(data).forEach(([key, value]) => {
      d[key] = value
    });
    if (data.id) {
      d.id = data.id
      triggerUpdate(d);
    }
    else {
      d.id = null
      triggerAdd(d);
    }
  }

  useEffect(() => {
    (async () => {
      reset(instance)
    })();
  }, [instance]);

  useEffect(() => {
    (async () => {
      if (resultUpdate.isSuccess || resultAdd.isSuccess){
        clearErrors()
        if(goNext){navigate(-1)}
      }
      if (resultUpdate.isError || resultAdd.isError){
        console.log(resultUpdate)
        if (resultUpdate.error?.formErrors || resultAdd.error?.formErrors){
          var formError = resultUpdate.error?.formErrors ? resultUpdate.error?.formErrors : resultAdd.error?.formErrors
          setFormError(formError, formState, setError)
        }
      }
    })();
  }, [resultUpdate, resultAdd]);






  return (
    <>
      <FormPaper>
        <>
          {isLoading === undefined ?
            <CircularProgress />
            :
            <>
              <FormInputText name="name" control={control} label="Typname" />
              <FormInputText name="comment" control={control} label="Kommentar" />
              <Button onClick={handleSubmit((data) => onSubmit(data,true))} variant={"contained"}>
                {" "}
                {params.id != 'new'? 'Aktualisieren':'Hinzufügen'}
              </Button>
              {
              params.id == 'new'?
                <Button onClick={handleSubmit((data) => onSubmit(data,false))} variant={"contained"}>
                  {" "}
                  {params.id != 'new'? 'Aktualisieren':'Hinzufügen und Weiter'}
                </Button>
                :
                <></>
              }
              <Button onClick={() => reset()} variant={"outlined"}>
                {" "}
                Reset{" "}
              </Button>
            </>
          }
        </>
      </FormPaper>

    </>
  );
}