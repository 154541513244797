import CustomDataGrid from '../../../components/DataGrid/DataGrid';
import { useGetTeamtypesQuery, useDeleteTeamtypeByIdMutation } from '../../../stores/services/TeamService';
import { DeleteButton } from '../../../components/DataGrid/DeleteButton';
import { EditButton } from '../../../components/DataGrid/EditButton';
// Internal
export default function TeamtypeGrid() {
    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 0,
            align: 'center'
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            align: 'left'
        },
        {
            field: 'actions',
            type: 'actions',
            align: 'left',
            getActions: (params) =>{return [
                <DeleteButton params={params.row} ></DeleteButton>,
                <EditButton params={params.row}></EditButton>
        ]}
        },
    ];
    return (
        <>
            <CustomDataGrid
                    columns = {columns}
                    getQuery = {useGetTeamtypesQuery}       
                    deleteQuery = {useDeleteTeamtypeByIdMutation}       
                />
      </>
    )
}
