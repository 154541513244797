import { useEffect } from 'react';
import { useForm } from "react-hook-form";
import {  useSelector } from 'react-redux'
import FormPaper from '../../components/FormFields/FormPaper';
import { FormInputText } from '../../components/FormFields/FormInputText';
import { useParams, useNavigate } from 'react-router-dom';
import { useGetUserDetailQuery } from '../../stores/services/UserService';

const defaultValues = {
    username:"",
    email: "",
    about_me: "",
  };


  export default function ProfilePage(){
    const navigate = useNavigate();
    const params = useParams();
    const { loading, userInfo, error, success } = useSelector((state) => state.auth)
    const { data: detail, getError, isLoading } = useGetUserDetailQuery(params.username)
    const methods = useForm({ defaultValues: defaultValues });
    const { handleSubmit, reset, control, setValue, watch, getValues, setError, clearErrors, formState, getFieldState } = methods;

    useEffect(() => {
      reset(detail)
    }, [navigate, detail])

    
    

    return (
      <FormPaper>
        <FormInputText name="username" control={control} label="Name" />
        <FormInputText name="email" control={control} label="Email" />
        <FormInputText name="about_me" control={control} label="Über mich" />
      </FormPaper>
   
    )
}

