import React from 'react';
import { useNavigate } from "react-router-dom";

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import style from './navItem.module.css';
import { navbarStyles } from './styles';
import NavItemHeader from './NavItemHeader.js';



const NavItem = props => {
  const navigate = useNavigate();
  const {id, label, icon, route, children } = props.item;
  const margin = props.margin!=null? props.margin + navbarStyles.levelmargin : 0
  
  if (children) {
    return <NavItemHeader route={route} item={props.item} margin={margin}/>;
  }
 
  return (
    <Stack direction="row" spacing={0} alignItems="center" className={style.navStack}> 
    <Box width={margin}></Box>
      <ListItem
        key={id}
        onClick={() => navigate(route)}
        className={style.navItem}
      >
        <ListItemIcon
          sx={navbarStyles.icons}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          sx={navbarStyles.text}
          primary={label}
        />
    </ListItem>
  </Stack>


  );
};

export default NavItem;