
import { useCallback } from 'react';

import { GridActionsCellItem, useGridApiContext, useGridRootProps } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

import Button  from '@mui/material/Button';
import {useNavigate} from 'react-router-dom';


export function EditButton( { params } ) {
    const baseUrl = window.location.pathname + '/'
    const navigate = useNavigate();
    const editRow = useCallback(
        (params) => () => {
            navigate(baseUrl+params.id)
        },
        [],
    );

  return (
        <GridActionsCellItem
            key = {`edit_${params.id}`}
            icon={<EditIcon />}
            label="Edit"
            onClick={editRow(params)}
        />
  );
}

export function NewButton( { params } ) {
    const gridProps = useGridRootProps();
    const baseUrl = window.location.pathname + '/'
    const navigate = useNavigate();
    const addNew = useCallback(
        () => () => {
            navigate(baseUrl+'new')
        },
        [],
    );

  return (
    <Button 
      key ='new'
      variant="text"
      onClick={addNew() }
    >
      <AddIcon></AddIcon> Neu 
  </Button>
  );
}



