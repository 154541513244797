import Box from '@mui/material/Box';
// Internal
import EventGrid from './EventGrid';
const EventsPage = () => {
    return (
        <>
            <Box sx={{ height: 400, width: '100%' }}>      
                <EventGrid ></EventGrid>
            </Box>
        </>
    )
}

export default EventsPage