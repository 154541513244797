import { createTheme } from '@mui/material/styles';
import {  deDE } from '@mui/x-date-pickers';


const theme = createTheme({
  palette: {
    primary: {
      main: '#198754',
    },
    secondary: {
      main: '#f44336',
    },
    deDE, // use 'de' locale for UI texts (start, next month, ...)
  },
});


export default theme