//External imports
import { useEffect, useRef } from 'react'
import Button from  '@mui/material/Button';

//import { GoogleLogin } from 'react-google-login';

import { useSetGoogleAccessMutationMutation } from '../../stores/services/UserService'
import { useGetScopesQuery } from '../../stores/services/GcalService';



  const loadScript = (src) =>
  new Promise((resolve, reject) => {

    if (document.querySelector(`script[src="${src}"]`)) { 
      return resolve() 
    }
   
    const script = document.createElement('script')
    script.src = src
    script.onload = () => resolve()
    script.onerror = (err) => reject(err)
    document.body.appendChild(script)
  })

const GoogleAuth = () => {
  const [triggerGoogleLogin, resultGoogleLogin] = useSetGoogleAccessMutationMutation()
  //const query = useGetScopesQuery()
  const { data , isSuccess, getError, isLoading } = useGetScopesQuery();
  const googleButton = useRef(null);
  const client = useRef(null);
  



  useEffect(() => {
    const src = 'https://accounts.google.com/gsi/client'
    const src1 = '//ajax.googleapis.com/ajax/libs/jquery/1.8.2/jquery.min.js'
    const src2 = 'https://apis.google.com/js/client:platform.js?onload=start'
    const id = "444807043214-k8ma37ime4i9skrv7rm2u1oo39a7a5tl.apps.googleusercontent.com"
    if (isSuccess!=true){
      return
    }
    loadScript(src)//.then(() => loadScript(src1)).then(() => loadScript(src2))
      .then(() => {
        client.current = window.google.accounts.oauth2.initCodeClient({
          client_id: id,
          scope: data.scopes.join(' '),
          ux_mode: 'popup',
          callback: (response) => {
            handleCredentialResponse(response)
          },
        });
        
      })
      .catch(
        (error) => {
          console.error(error) }
        )


  }, [isSuccess])

  function handleCredentialResponse(response) {
    const d = {
        code : response.code,
        scope : response.scope,
        user_id : 1
    }
    const revokeToken = async (token) => {
      const url = `https://accounts.google.com/o/oauth2/revoke?token=${token}`
      const response = await fetch(url, { method: 'POST' })
      console.log(response)
      return response.ok
    }
    //revokeToken(response.credential)
    triggerGoogleLogin(d)
  }

  return (
    <Button 
      variant="text"  
      onClick={() => {
        client.current.requestCode();
    }}>Google</Button>
   

  )
}

export default GoogleAuth
