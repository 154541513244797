// authActions.js
import { createAsyncThunk } from '@reduxjs/toolkit'

export const openMessageboxAction = createAsyncThunk(
  'message/message',
  async (name, { getState }) => {
    return name + getState().counter.value;
  }
)

