import React from "react";
import { useForm, Controller } from "react-hook-form";
//import TextField from "@material-ui/core/TextField";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Stack from '@mui/material/Stack';
import moment from "moment";
import { getStyleValue } from "@mui/system";

export const FormInputDateTime = ({ name, control, label, minDate=null, customOnChange=null, shift=null, time=true }) => {
  const minTime = (value) => value? moment(value).isSame(moment(minDate), 'day')? moment(minDate):null:null

  const onChangeWithShift = (newValue, oldValue, onChange) => {
    newValue = moment(newValue)
    oldValue = moment(oldValue)
    if (shift!=null) {
      if (newValue._isValid == true && oldValue._isValid){
        var diff = newValue.diff(oldValue, 'seconds')
        if (diff !== 0) {
          shift.set(shift.field, moment(shift.get(shift.field)).add(diff, 'seconds'))
        }        
     } 
    }
     if (newValue._isValid == true) {
      onChange(newValue)
     } 
    }

  return (
    <Controller
      name={name}
      control={control}
    
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <> 
          <Stack direction="row" spacing={2}>

            <DatePicker
              size="small"
              inputFormat="DD.MM.yyyy"
              helperText={error ? error.message : null}
              label={label}
              value={value}
              onChange={(e) => onChangeWithShift(e,value,onChange ) }
              renderInput={(params) => <TextField {...params} />}
              minDate = {moment(minDate)}
            />
{ time &&
              <TimePicker
                ampm={false}
                value={value}
                onChange={(e) => onChangeWithShift(e,value,onChange )}
                renderInput={(params) => <TextField {...params} />}
                minTime = {minTime(value)?minTime(value):null}
            />
}
          </Stack>
        </>
      )}
    />
  );
};