
import { GridActionsCellItem, useGridRootProps } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@mui/material';

export function DeleteButton( { params } ) {
  const gridProps = useGridRootProps();
  const deleteRow = gridProps.componentsProps.root.deleteRow
 
  return (
    <GridActionsCellItem 
                    key = {`delete_${params.id}`}
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={deleteRow(params)}
                />
  );
}


export function DeleteMultipleButton () {
  const gridProps = useGridRootProps();
  const deleteRows = gridProps.componentsProps.root.deleteRows
  return (
    <Button 
      variant="text"
      onClick={deleteRows() }
    >
      <DeleteIcon></DeleteIcon> Löschen
  </Button>
  );
}
