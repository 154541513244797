

export const errorHandler = (error) => {
    if (error.response) {
        return error.response.data
    }

    if (error.response && error.response.data.message) {
        return error.response.data.message
    } else {
        return error.message
    }
}

