// authActions.js
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { errorHandler } from './errorResponse'

const backendURL = process.env.REACT_APP_BASE_API_URL


export const registerUser = createAsyncThunk(
  'auth/register',
  async ({username, email, password}, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      await axios.post(
        `${backendURL}/api/users/`,
        {username, email, password},
        config
      )
    } catch (error) {
      return  rejectWithValue(errorHandler(error))
    }
  }
)

export const userLogin = createAsyncThunk(
    'auth/login',
    async ({ email, password }, { rejectWithValue }) => {
      
      try {
        // configure header's Content-Type as JSON
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic  ' +  btoa(email + ":" + password),
          },
        }
        const { data } = await axios.post(
          `${backendURL}/api/tokens/`,
          { email, password },
          config
        )
        console.log(data)
        // store user's token in local storage
        localStorage.setItem('accessToken', data.access_token)
        localStorage.setItem('refreshToken', data.refresh_token)
        return data
      } catch (error) {
        return rejectWithValue(errorHandler(error))
      }
    }
  )