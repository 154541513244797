// authActions.js
import { createAsyncThunk } from '@reduxjs/toolkit'

 export const openSnackbarAction = createAsyncThunk(
  'notify/snackbar',
  async (name, { getState }) => {
    return name + getState().counter.value;
  }
 )


