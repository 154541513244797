
import { createSlice } from '@reduxjs/toolkit'
import { registerUser, userLogin } from '../actions/authActions'

import { openSnackbarAction } from '../actions/notifyActions'




// initialize userToken from local storage
const accessToken = localStorage.getItem('accessToken')
    ? localStorage.getItem('accessToken')
    : null
const refreshToken = localStorage.getItem('refreshToken')
    ? localStorage.getItem('refreshToken')
    : null

const initialState = {
    loading: false,
    userInfo: null,
    accessToken,
    refreshToken,
    error: null,
    success: false,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            localStorage.removeItem('accessToken') // deletes token from storage
            localStorage.removeItem('refreshToken') // deletes token from storage
            state.loading = false
            state.userDetail = null
            state.accessToken = null
            state.refreshToken = null
            state.error = null
            //window.location = window.location.protocol + "//" + window.location.host + "/"

          },
        setUserDetail: (state, { payload }) => {
            state.userDetail = payload
        },
        setTokens: (state, { payload }) => {
            state.accessToken = payload.access_token
            state.refreshToken = payload.refresh_token
            localStorage.setItem('accessToken',payload.access_token) // set token in storage
            localStorage.setItem('refreshToken',payload.refresh_token) // set token in storage
        },
    },
    extraReducers: {
        // login user
        [userLogin.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [userLogin.fulfilled]: (state, { payload }) => {

            state.loading = false
            state.userDetail = null
            state.accessToken = payload.access_token
            state.refreshToken = payload.refresh_token
    
        },
        [userLogin.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        // register user
        [registerUser.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [registerUser.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.success = true // registration successful
        },
        [registerUser.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        // open snackbar
        [openSnackbarAction.fulfilled]: ( state, { payload }) => {
            console.log(state)
        }
    },
})
export default authSlice.reducer

export const { logout, setUserDetail, setTokens } = authSlice.actions