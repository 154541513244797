import CustomDataGrid from '../../../components/DataGrid/DataGrid';
import { useGetTeamgroupsQuery, useDeleteTeamgroupByIdMutation } from '../../../stores/services/TeamService';
import { DeleteButton } from '../../../components/DataGrid/DeleteButton';
import { EditButton } from '../../../components/DataGrid/EditButton';

// Internal

  export default function TeamgroupGrid()  {
    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 0,
            align: 'center'
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            align: 'left'
        },
        {
            field: 'actions',
            type: 'actions',
            align: 'left',
            getActions: (params) =>{return [
                    <DeleteButton params={params.row} ></DeleteButton>,
                    <EditButton params={params.row}></EditButton>
            ]}
        },



    ];

console.log(useGetTeamgroupsQuery)
    return (
        <>
            <CustomDataGrid
                    columns = {columns}
                    getQuery = {useGetTeamgroupsQuery}
                    deleteQuery = {useDeleteTeamgroupByIdMutation}
                />
      </>
    )
}

