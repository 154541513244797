import { useForm } from "react-hook-form";
import {  useEffect, useRef, useState } from 'react';


import CustomDataGrid from '../../components/DataGrid/DataGrid';
import { getChildValue } from '../../utilities/DataGridHelper';
import moment from 'moment';
import { useGetEventsQuery, useDeleteEventByIdMutation } from '../../stores/services/EventService';
import { useEditEventPreviewByIdMutation  } from '../../stores/services/EventService';

import { FormInputDateTime } from '../../components/FormFields/FormInputDateTime';
import FormPaper from '../../components/FormFields/FormPaper';
import Stack from '@mui/material/Stack';
import { DeleteButton } from '../../components/DataGrid/DeleteButton';
import { EditButton } from '../../components/DataGrid/EditButton';
import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';

// Internal
//==================
import SelectionTeam from "../../components/Sub/SelectionTeams";
import { getSearchParam, setSearchParam } from "../../utilities/searchParamHelper";
const start_saved = getSearchParam('start') != null? moment.max(moment(getSearchParam('start')),moment().startOf('h')) :moment().startOf('h')
const defaultValues = {
    start: start_saved,
    end: getSearchParam('end')? moment.max(moment(getSearchParam('end')),start_saved) : moment().endOf('year') ,
    //start:moment().startOf('h'),
    //end:moment({year: 2023, month: 11, day:31,hour:23, minute:59, second:59}),
};
// const getEventsQuery = () => { return useGetEventsQuery()}
// Internal
const EventGrid = () => {
    
    const start_saved_2 = getSearchParam('start') != null? moment.max(moment(getSearchParam('start')),moment().startOf('h')) :moment().startOf('h')
    defaultValues.start = getSearchParam('start') != null? moment.max(moment(getSearchParam('start')),moment().startOf('h')) :moment().startOf('h')
    defaultValues.end = getSearchParam('end')? moment.max(moment(getSearchParam('end')),start_saved_2) : moment().endOf('year')
    const columns = [
        {
            main:true,
            field: 'id',
            headerName: 'ID',
            flex: 0,
            align: 'center',
            hide:true
        },
        {
            field: 'start',
            headerName: 'Start',
            align: 'left',       
            width: 250,
            type: 'dateTime',
            valueFormatter: params => 
            moment(params?.value).format("dddd, DD.MM.YYYY HH:mm"),            
        },
        {   
            main:true,
            field: 'team',
            headerName: 'Mannschaft',
            minWidth: 250,
            align: 'left', 
            valueGetter: (params) => {
                return getChildValue(params.row.team,'name');
            }
        },
        {   
            main:true,
            field: 'preview',
            headerName: 'Vorschau',
            
            align: 'center', 
            renderCell: (params) => ( RenderCheckBox(params.row.preview, params.row.id))
        },
        {   
            main:true,
            field: 'summary',
            headerName: 'Text',
            flex: 0.8,
            minWidth: 300,
            align: 'left'    

        },
        {   
            field: 'Location',
            headerName: 'Ort',
            minWidth: 300,
            align: 'left', 
            valueGetter: (params) => {
                return getChildValue(params.row.location,'shortText');
            }
        },
        {   
            field: 'Eventtype',
            headerName: 'Art',
            minWidth: 200,
            align: 'left', 
            valueGetter: (params) => {
                return getChildValue(params.row.eventtype,'text');
            }
        },
        {
            field: 'actions',
            type: 'actions',
            align: 'left',
            getActions: (params) => [
                    <DeleteButton params={params.row} ></DeleteButton>,
                    <EditButton params={params.row}></EditButton>
            ]
        },
    ];
    let start = null
    const methods = useForm({ defaultValues: defaultValues });
    const { handleSubmit, reset, control, setValue, watch, getValues, setError, clearErrors, formState, getFieldState } = methods;
    const watchStart = watch("start");
    const watchEnd = watch("end");
    const startRef = useRef(getValues('start'));
    const endRef = useRef(getValues('end'));
    const teamIdsInit = getSearchParam('teams')? getSearchParam('teams') : []
    const teamIdsRef = useRef();
    const teamsGetFinished = useRef(false);
    const [teamsGetFinishedState, setTeamsGetFinishedState] = useState(false);
    const [loadData, setLoadData] = useState(false);
    const [trigger, setTrigger] = useState(false);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 25,
        page: 0,
      });

    useEffect(() => {
        startRef.current = getValues('start')
      }, [watchStart]);

    useEffect(() => {
        endRef.current = getValues('end')
      }, [watchEnd]);
    
    useEffect(() => {
        if (teamsGetFinishedState == true)
        {
            setLoadData(true)
        }
        
    }, [teamsGetFinishedState]);


    const args = {
        start: startRef.current.toISOString(),
        end: endRef.current.toISOString(),
        teams: teamIdsRef.current
    }

    const triggerRefetch = () => {
        setSearchParam('teams', teamIdsRef.current)
        setSearchParam('start', startRef.current)
        setSearchParam('end', endRef.current)
        setTrigger(!trigger)  
    }

    const sorting = [{ field: 'start', sort: 'asc' }]

    return (
        <>
            <FormPaper>
                <Stack  direction="row"   
                        justifyContent="center"
                        alignItems="center" 
                        spacing={2}>
                    <FormInputDateTime name="start" control={control} label="Start" />
                    <FormInputDateTime name="end" control={control} label="Ende" minDate={start} />
                </Stack>

                <SelectionTeam  
                    teamIdsInit={teamIdsInit}
                    teamIds={teamIdsRef} 
                    queryFinished={setTeamsGetFinishedState}> 
                </SelectionTeam>

                <Button onClick={() => {triggerRefetch()}} variant={"contained"}>
                    {" "}
                    Laden{" "}
                </Button>
            </FormPaper>
            <>
                {loadData==false?
                    <CircularProgress />
                :
                <CustomDataGrid
                columns={columns}
                deleteQuery={useDeleteEventByIdMutation}
                getQuery={useGetEventsQuery}
                getArgs = {args}
                trigger = {trigger}
                sort = {sorting}
                />

                }
            </>
            
        </>
    )
}

function RenderCheckBox(isChecked, id) {
    if (isChecked==null){
        isChecked=false
    }
    const [checked, setChecked] = useState(isChecked); // Initiated react binded value with param from `rows`
    const [triggerPreviewUpdate, resulPreviewUpdate] = useEditEventPreviewByIdMutation()
    // Handler for user clicks to set checkbox mark or unset it
    const handleChange = (params) => {
        console.log(params)
        setChecked(!checked);
        triggerPreviewUpdate({id: id, isTrue:!checked})
    };
    //The bind for dynamic mark/unmark: checked={checked}
    //The handler for user clicks: onChange={handleChange}
    return (
      <Checkbox
        label="some text"
        size="lg"
        checked={checked} 
        onChange={handleChange} 
      />
    );
  }


export default EventGrid