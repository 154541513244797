import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// MUI
//-----------------------
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

// Internal
//------------------
import store from '../../stores/store';
import { openSnackbar } from '../../stores/slices/notifySlice';

const SubmitButton = ({form, queryAdd, queryEdit = null, stay=true, reset=true}) => {
    const [triggerUpdate, resultUpdate] = queryEdit()
    const [triggerAdd, resultAdd] = queryAdd()
    const [isLoading, setLoading] = useState(false);
    const [goNextExcecute, setGoNextExcecute] = useState(false);
    const params = useParams();
    const navigate = useNavigate();
    useEffect(() => {
      ( async () => {     
        setLoading(resultUpdate.isLoading || resultAdd.isLoading)
        if (resultUpdate.isSuccess || resultAdd.isSuccess){
          const snackbar = {
            severity:'success',
            vertical:'top',
            horizontal:'center',
            message:'Erfolgreich aktualisiert'
          }
          store.dispatch(openSnackbar(snackbar))
          form.clearErrors()
          if(goNextExcecute){navigate(-1)}
        }
        if (resultUpdate.isError || resultAdd.isError){
          if (resultUpdate.error?.formErrors || resultAdd.error?.formErrors){
            var formError = resultUpdate.error?.formErrors ? resultUpdate.error?.formErrors : resultAdd.error?.formErrors
            form.setFormError(formError, form.formState, form.setError)
          }
        }
      })();
    }, [resultUpdate, resultAdd]);

    const handleSubmit = async (data, gobackExecute) => {
      setLoading(true);
      onSubmit(data, gobackExecute);
    }

    const onSubmit = async (data, gobackExecute) => {
      setGoNextExcecute(gobackExecute)
      const d = {}
      Object.entries(data).forEach(([key, value]) => {
        d[key] = value
      });
      if (params.id != 'new') {
        d.id = params.id

        triggerUpdate(d);
      }
      else {
        triggerAdd(d);
      }
    }

    return (
      <>
        <Button
          variant="contained"
          color="primary"

          disabled={isLoading}
          startIcon={isLoading ? <CircularProgress size={20} /> : null}
          onClick={form.handleSubmit((formdata) => handleSubmit(formdata,true))}
        >
            {" "}
            {"Speichern"}
        </Button>     
        {   
          stay?
        <Button
          variant="contained"
          color="primary"

          disabled={isLoading}
          startIcon={isLoading ? <CircularProgress size={20} /> : null}
          onClick={form.handleSubmit((formdata) => handleSubmit(formdata,false))}
        >
            {" "}
            {"Speichern und weiter"}
        </Button>
        :
        null
        }
        {
          reset?
        <Button onClick={() => form.reset()} variant={"outlined"}>
                {" "}
                Reset{" "}
              </Button>
            
            :null
          }
      </>
        
    );
  };

  export default SubmitButton