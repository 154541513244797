import { createSlice, current as cur, createListenerMiddleware, current } from '@reduxjs/toolkit';
import { addTaskProgressAction } from '../actions/taskActions';

import { taskApi } from '../services/TaskService';
 
const initialState = {
  progress: {},
};

export const stateChangeMiddleware = store => next => action => {
  const prevState = store.getState().task?.progress; // Get the previous state before the action is dispatched
  const result = next(action); // Dispatch the action

  const nextState = store.getState().task?.progress; // Get the next state after the action is dispatched
  if (prevState!=nextState){
    const changed = findChangedEntries(prevState, nextState)

  }
  // Compare the previous state with the next state to identify the changed data
  //const changedData = findChangedData(prevState, nextState);

  // Execute your desired function with the changed data
  //if (changedData) {
  //  yourFunction(changedData);
  //}

  return result;
};


function findChangedEntries(prevDict, nextDict) {
  const changedEntries = {};

  // Iterate through the keys of the nextDict
  for (const key in nextDict) {
    if (nextDict.hasOwnProperty(key)) {
      const nextValue = nextDict[key];
      const prevValue = prevDict[key];

      // Check if the key exists in the prevDict
      if (prevDict.hasOwnProperty(key)) {
        // If the values are both objects, recursively compare the nested dictionaries
        if (typeof nextValue === 'object' && typeof prevValue === 'object') {
          const nestedChanges = findChangedEntries(prevValue, nextValue);

          if (Object.keys(nestedChanges).length > 0) {
            // Store the nested changes under the current key
            changedEntries[key] = nestedChanges;
          }
        }
        // If the values are not equal, store the changed entry at the "id" level
        else if (prevValue !== nextValue) {
          changedEntries[key] = nextValue;
        }
      }
      // If the key is not present in the prevDict, store the new entry
      else {
        changedEntries[key] = nextValue;
      }
    }
  }

  return changedEntries;
}

export const listnerTask = createListenerMiddleware()

listnerTask.startListening(
  {
    type: 'taskProgress/addProgress',
    effect: async (action, listnerApi) => {
      const  { data, refetch, isSuccess }   = listnerApi.dispatch(taskApi.endpoints.getTaskStatus.initiate(action.payload.url))
      update_progress(listnerApi, action.payload,refetch )
    },
  }
)

listnerTask.startListening(
  {
    type: 'taskProgress/updateProgress',
    effect: async (action, listnerApi) => {
 
    },
  }
)

listnerTask.startListening(
  {
    type: 'taskProgress/setFail',
    effect: async (action, listnerApi) => {

    },
  }
)

listnerTask.startListening(
  {
    type: 'taskProgress/setFinished',
    effect: async (action, listnerApi) => {
  
    },
  }
)

const update_progress = async (store, current, refetch ) => {
  //const result = await store.dispatch(taskApi.endpoints.getTaskStatus.initiate(current.url))

  const  { data,  isSuccess }   = await refetch()

  if (isSuccess){
      const payload =  {
      id:current.id,
      ident:current.ident,
      msg: data['status'] ,
      current:data['current'] , 
      total:data['total'], 
      percentage: data['current']/data['total']*100,
      task:current.task,
      statusurl:current.statusurl 
      }
      if (data['state'] != 'PENDING' && data['state'] != 'PROGRESS') {

        if ('result' in data) {
            store.dispatch(setFinished(payload))
        }
        else {
            store.dispatch(setFail(payload))
        }
      }
      else {
          if (data['state'] == 'PROGRESS'){
                  store.dispatch(updateProgress(payload ))
          }
          setTimeout(async () => await update_progress(store, current, refetch), 2000);
      }
  }
}



const createProgress = (payload, status) => {
  const { task, ident, id, msg, current, total, percentage, statusurl } = payload;
  const progress = {
    msg,
    current,
    total,
    percentage,
    status: status,
  };
  return progress
}

const setProgress = (state, payload, status) => {
    const { task, ident, id, msg, current, total, percentage, statusurl } = payload;
    if (!state.progress[ident]) {
      state.progress[ident] = {};
    }
    const progress = createProgress(payload, status)
    progress['statusurl'] = state.progress?.[ident]?.[id]?.['statusurl']
    state.progress[ident][id] = progress
}


const startProgress = (state, payload, status) => {
  const { task, ident, id, msg, current, total, percentage, statusurl } = payload;
  if (!state.progress[ident]) {
    state.progress[ident] = {};
  }
  const progress = createProgress(payload, status)
  progress['statusurl'] = statusurl
  
  state.progress[ident][id] = progress
}

const taskSlice = createSlice({
  name: 'taskProgress',
  initialState,
  reducers: {
    addProgress: (state, { payload }) => {
      startProgress(state, payload, 0)
    },
    addStatusUrl: (state, { payload }) => {
      startProgress(state, payload, 0)
    },
    updateProgress: (state, { payload }) => {
        setProgress(state, payload, 1)
    },
    setFail: (state, { payload }) => {
        setProgress(state, payload, 2)
    },
    setFinished: (state, { payload }) => {
        setProgress(state, payload, 3)
    },
    clearProgress: (state, { payload }) => {
        const { task, ident, id } = payload;
        const progress = state.progress[ident]?.[id] 
        if (progress){
            delete state.progress[ident]?.[id] 
        }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addTaskProgressAction.pending, (state) => {
        // Handle pending state if needed
      })
      .addCase(addTaskProgressAction.fulfilled, (state, { payload }) => {
        // Handle fulfilled state if needed
      })
      .addCase(addTaskProgressAction.rejected, (state, { payload }) => {
        // Handle rejected state if needed
      });
  },
});

export default taskSlice.reducer;


export const { addProgress, updateProgress, setFail, setFinished, clearProgress } = taskSlice.actions