

const setSearchParam = (key, value) => {

    let paramsstore = localStorage.getItem('searchParams')
    if (paramsstore == null){
        paramsstore = JSON.stringify({})
    }
    
    paramsstore = JSON.parse(paramsstore)
    

    let sitestore = paramsstore[window.location.href]
    
    if (sitestore==null){
        sitestore = {}
    }
    sitestore[key] = value
    paramsstore[window.location.href] = sitestore
    localStorage.setItem('searchParams', JSON.stringify(paramsstore))    
}

const getSearchParam = (key) => {
    let paramsstore = localStorage.getItem('searchParams')
    if (paramsstore==null){
        return null
    }
    paramsstore = JSON.parse(paramsstore)
    let sitestore = paramsstore[window.location.href]
    if (sitestore==null){
        return null
    }
    return sitestore[key]
}

export {
    setSearchParam,
    getSearchParam
}