import * as React from 'react';
import { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import FormPaper from '../../components/FormFields/FormPaper';
import { FormInputText } from '../../components/FormFields/FormInputText';
import { FormInputDropdown } from '../../components/FormFields/FormInputDropDown';
import { FormInputCheckbox } from '../../components/FormFields/FormInputCheckbox';
import { useApi } from '../../contexts/ApiProvider';
import { setFormError, createOptions } from '../../services/ApiService';


const defaultValues = {
  id: "",
  name: "",
  teams: [],
  home: false,
};


export default function ClubEditPage() {
  const api = useApi();
  const params = useParams();
  const methods = useForm({ defaultValues: defaultValues });
  const { handleSubmit, reset, control, setValue, watch, getValues, setError, clearErrors, formState, getFieldState } = methods;
  const [instance, setInstance] = useState()
  const navigate = useNavigate();


  const url = '/clubs/'
  const url_api = '/clubs/'
  const [teams, setTeams] = useState()


  const onSubmit = async (data, goback) => {
    const d = {
      //id: data.id,
      name: data.name,
      teams: data.teams,
      home: data.home
    }
    let response = undefined
    if (data.id) {
      response = await api.put(url_api + instance.id, d);
    }
    else {
      response = await api.post(url_api, d);
    }
    if (response.ok) {
      clearErrors()
      setInstance(response.body);
      if(goback){navigate(-1)}
    }
    else {
      setFormError(response, formState, setError)
    };
  };

  useEffect(() => {
    (async () => {
      const response = await api.get('/teams/')
      if (response.ok) {
        setTeams(createOptions(response.body.data, 'name', 'id', { label: 'Kein Team', value: "No" }))
      }

    })();
  }, [api]);

  useEffect(() => {
    (async () => {
      if (params.id != 'new') {
      const response = await api.get(url_api + params.id)
      if (response.ok) {
        setInstance(response.body);
        reset(response.body)
      }
      else {
        setInstance(null);
      }

    }      
    else{
      setInstance(null);
    }
    })();
  }, [api]);





  return (
    <>
      <FormPaper>
        <>
          {instance === undefined || teams === undefined ?
            <CircularProgress />
            :
            <>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <FormInputCheckbox name="home" control={control} label="Heim" />
                <FormInputText name="name" control={control} label="Vereinsname" />
              </Stack>
              <FormInputDropdown
                name="teams"
                control={control}
                label="Teams"
                options={teams}
                multiple={true}
                valuekey='id'
              />


<Button onClick={handleSubmit((data) => onSubmit(data,true))} variant={"contained"}>
                {" "}
                {params.id != 'new'? 'Aktualisieren':'Hinzufügen'}
              </Button>
              {
              params.id == 'new'?
                <Button onClick={handleSubmit((data) => onSubmit(data,false))} variant={"contained"}>
                  {" "}
                  {params.id != 'new'? 'Aktualisieren':'Hinzufügen und Weiter'}
                </Button>
                :
                <></>
              }
              <Button onClick={() => reset()} variant={"outlined"}>
                {" "}
                Reset{" "}
              </Button>
            </>
          }
        </>
      </FormPaper>

    </>
  );
}