import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { create } from 'zustand';
import DOMPurify from 'isomorphic-dompurify';

const useErrorDialogStore = create((set)=>({
    title:"",
    content:<></>,
    onSubmit:undefined,
    close:()=>
        set({
            onSubmit:undefined
        })

}))

export const errorDialog = (title, content, onSubmit ) => {

  useErrorDialogStore.setState({
        title, 
        content, 
        onSubmit,
    })
    
}

export function ErrorDialog() {
    const { title, content, onSubmit, close} = useErrorDialogStore()
  return (
        
      <Dialog
        maxWidth="sm"
        fullWidth
        open={Boolean(onSubmit)}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
            { title } 
        </DialogTitle>

        <DialogContent dividers>
          { content }
        </DialogContent>
        <DialogActions>
        <Button 
            color="error" 
            variant='contained'
            onClick={() => {
                if (onSubmit){
                    onSubmit()
                    };
                close()
            }}
                autoFocus>
            Ok
          </Button>

        </DialogActions>
      </Dialog>
  );
}