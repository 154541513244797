import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

import NavItem from './NavItem';
import { navbarStyles } from './styles';
import style from './navItem.module.css';
import { useNavigate } from "react-router-dom";

const NavItemHeader = (props) => {
  const navigate = useNavigate();
  const { item } = props;
  const { id, label, icon, route: headerPath, children } = item;
  const margin = props.margin != null ? props.margin + navbarStyles.levelmargin : 0
  const route = props.route
  const location = useLocation();
  const [expanded, setExpand] = useState(
    location.pathname.includes(headerPath) && !location.pathname.endsWith(headerPath)
  );
  const onExpandChange = e => {
    e.preventDefault();
    setExpand(expanded => !expanded);
  };

  return (
    <>
      <Stack direction="row" spacing={0} alignItems="center" className={style.navStack}>
        <Box width={props.margin}></Box>
        <ListItem
          key={id}
          onClick={() => navigate(item.route)}
          className={style.navItem}
        >
          <ListItemIcon
            sx={
              { ...navbarStyles.icons }
            }
          >
            {icon}
          </ListItemIcon>
          <ListItemText
            sx={navbarStyles.text}
            primary={label}
          />
        </ListItem>
        {<ExpandMoreIcon
          className={`${style.navItemHeaderChevron} ${expanded && style.chevronExpanded}`}
          onClick={onExpandChange}
        />}
      </Stack>
      <Collapse in={expanded} >
        {children.map((item, index) => (
          <NavItem
            margin={margin}
            key={`${item.label}-${index}`} item={item}
            className={style.navChildrenBlock}
          />
        ))}
      </Collapse>
    </>
  )
};



export default NavItemHeader;