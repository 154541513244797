import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AdbIcon from '@mui/icons-material/Adb';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { profileItems } from './consts/profileItems';
import { OpenContext } from './OpenContext';
import { navbarStyles } from '../Navbar/styles';
import { useNavigate } from 'react-router-dom';
import { useGetUserDetailsQuery } from '../../stores/services/UserService';
import { useDispatch, useSelector } from 'react-redux'
import { setUserDetail, logout } from '../../stores/slices/authSlice';
//import GoogleAuth from '../Login/google';
import GoogleAuth from '../Login/google_auth';

const drawerWidth = navbarStyles.drawer.width

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));




export default function Appbar({children}) {
  const [skip, setSkip] = React.useState(true)
  const { userInfo, accessToken } = useSelector((state) => state.auth)
  const sele = useSelector(
    (state) => state.auth
  )
  const dispatch = useDispatch()
  //setSkip(accessToken==null)
  
  let location = useLocation();
  const {navbarOpen, setNavbarOpen} = React.useContext(OpenContext)
  const toggleOpen = () => {
    setNavbarOpen(!navbarOpen);
  };
  const navigate = useNavigate()


  const [anchorElUser, setAnchorElUser] = React.useState(null);


  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };


  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClickUserMenu = (event, route) => {
    setAnchorElUser(null);
    navigate(route);
  };


  return (
    

    <AppBar  position="fixed" open={navbarOpen}>
    <Toolbar disableGutters>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={toggleOpen}
        edge="start"
        sx={{ mr: 2,
        ml:2 }}

        //sx={{ mr: 2, ...(open && { display: 'none' }) }}
      >
        <MenuIcon />
      </IconButton>
      <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
      <Typography variant="h6" noWrap component="div" sx={{flexGrow:1}}>
     
      </Typography>
      
      <Box sx={{ flexGrow: 0 }}>
        <Tooltip title="Open settings">
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 , mr:2}}>
            <Avatar  />
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: '45px' }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <MenuItem>
            <GoogleAuth/>
          </MenuItem>
          { accessToken &&

          <MenuItem 
              key='profile'
              onClick={(e)=>handleClickUserMenu(e,`/user/me`)}>
              <Typography textAlign="center">Profile</Typography>
            </MenuItem>
       
          }
          { accessToken?
          <MenuItem 
              key='logout'
              onClick={
                (e)=>{console.log('Should logout'); dispatch(logout())}
                }>
              <Typography textAlign="center">Logout</Typography>
            </MenuItem>
          :
          <MenuItem 
              key='login'
              onClick={(e)=>handleClickUserMenu(e, '/login')}>
              <Typography textAlign="center">Login</Typography>
            </MenuItem>
          }


        </Menu>
      </Box>
    </Toolbar>
  </AppBar>
  );
}