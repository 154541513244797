import * as React from 'react';
import { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useParams, useNavigate,  } from 'react-router-dom';

//import TextField from "@material-ui/core/TextField";
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import FormPaper from '../../../components/FormFields/FormPaper';
import { FormInputText } from '../../../components/FormFields/FormInputText';
import { FormInputDropdown } from '../../../components/FormFields/FormInputDropDown';
import { useGetTeamByIdQuery, useAddTeamByIdMutation, useEditTeamByIdMutation, useGetTeamgroupsQuery } from '../../../stores/services/TeamService';
import { useGetClubsQuery } from '../../../stores/services/ClubService';
import { useGetGcalsQuery } from '../../../stores/services/GcalService';
import SubmitButton from '../../../components/DataGrid/SubmitButton';
import { setFormError} from '../../../stores/services/ApiBase';

const defaultValues = {
  id: null,
  name: "",
  gcal: null,
  club_id:null,
  teamgroup_id:null,
  url_ical:null,
  excel_col:null,
};


export default function TeamEditPage() {
  const params = useParams();
  const form = useForm({ defaultValues: defaultValues });
  const { handleSubmit, reset, control, setValue, watch, getValues, setError, clearErrors, formState, getFieldState } = form;
  const navigate = useNavigate();
  const { data:instance , getError, isLoading } = useGetTeamByIdQuery(params.id,{skip: params.id=='new'});


  useEffect(() => {
    (async () => {
      reset(instance)
    })();
  }, [instance]);

  return (
    <>
      <FormPaper>
        <>
          {isLoading === true  ?
            <CircularProgress />
            :
            <>
              <FormInputText name="name" control={control} label="Mannschaftsname" />
              <FormInputDropdown name="club_id" control={control} label="Verein" optionsQuery={useGetClubsQuery} optionKey='id' optionValue='name' />
              <FormInputDropdown name="gcal" control={control} label="Google Kalender" optionsQuery={useGetGcalsQuery} optionKey='id' optionValue='summary' />
              <FormInputDropdown name="teamgroup_id" control={control} label="Gruppe" optionsQuery={useGetTeamgroupsQuery} optionKey='id' optionValue='name' />
              <FormInputText name="url_ical" control={control} label="BFV Url" />
              <FormInputText name="excel_col" control={control} label="Excelspalte (numerisch)" />
              <SubmitButton
                form = {form}
                queryAdd= {useAddTeamByIdMutation}
                queryEdit= {useEditTeamByIdMutation}
              >
              </SubmitButton>
            </>
          }
        </>
      </FormPaper>

    </>
  );
}