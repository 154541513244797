import * as React from 'react';

import  Paper  from '@mui/material/Paper';

export default function FormPaper({  children }) {
  return (
    <Paper
      sx={{
        width:'100%'
        }

      }
      style={{
        display: "grid",
        gridRowGap: "20px",
        padding: "20px",
        
      }}
    >
        {children}
    </Paper>
  );
}