import * as React from 'react';
import { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from 'react-router-dom';

//import TextField from "@material-ui/core/TextField";
import CircularProgress from '@mui/material/CircularProgress';

// Internal
//-----------------------
import FormPaper from '../../../components/FormFields/FormPaper';
import { FormInputText } from '../../../components/FormFields/FormInputText';
import { FormInputDropdown } from '../../../components/FormFields/FormInputDropDown';
import { useGetTeamgroupByIdQuery, useAddTeamgroupByIdMutation, useEditTeamgroupByIdMutation, useGetTeamtypesQuery } from '../../../stores/services/TeamService';
import SubmitButton from '../../../components/DataGrid/SubmitButton';


const defaultValues = {
  id: null,
  name: "",
  comment: "",
};


export default function TeamgroupEditPage() {
  const params = useParams();
  const form = useForm({ defaultValues: defaultValues });
  const { handleSubmit, reset, control, setValue, watch, getValues, setError, clearErrors, formState, getFieldState } = form;
  const navigate = useNavigate();
  const [goNext, setGoNext] = useState(false);
  const { data:instance , getError, isLoading } = useGetTeamgroupByIdQuery(params.id,{skip: params.id=='new'});

  useEffect(() => {
    (async () => {
      reset(instance)
    })();
  }, [instance]);

  return (
    <>
      <FormPaper>
        <>
          {isLoading === true  ?
            <CircularProgress />
            :
            <>
              <FormInputText name="name" control={control} label="Gruppenname" />
              <FormInputText name="comment" control={control} label="Kommentar" />
              <FormInputDropdown name="teamtype_id" control={control} label="Typ" optionsQuery={useGetTeamtypesQuery} optionKey='id' optionValue='name' />
              <SubmitButton
                form = {form}
                queryAdd= {useAddTeamgroupByIdMutation}
                queryEdit= {useEditTeamgroupByIdMutation}
              >
              </SubmitButton>
            </>
          }
        </>
      </FormPaper>

    </>
  );
}