
import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from './ApiBase'

export const eventApi = createApi({
    reducerPath: 'eventApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['event','Eventtypes'],
    endpoints: (builder) => ({
        getEvents: builder.query({
            query: (params=null) =>({
                //url: params=null?'api/events/': `api/events/?${new URLSearchParams(params || {}).toString()}` ,
                url:'api/events/',
                method: 'GET',
                params:params
            }),
            providesTags: () => ['event']
          
        }),
        getEventById: builder.query({
            query: (id) =>({
                url: `api/events/${id}`,
                method: 'GET',
            }),
            providesTags: () => ['event']
        }),
        getEventByName: builder.query({
            query: (name) =>({
                url: `api/events/${name}`,
                method: 'GET',
            }),
            providesTags: () => ['event']
        }),
        addEventById: builder.mutation({
            query:(data) => ({
                url: `api/events/`,
                method: 'post',
                body:data
            }),
            invalidatesTags: ['event'],
        }),
        editEventById: builder.mutation({
            query:(data) => ({
                url: `api/events/${data.id}`,
                method: 'put',
                body:data
            }),
            invalidatesTags: ['event'],
        }),
        deleteEventById: builder.mutation({
            query:(id) => ({
                url: `api/events/${id}`,
                method: 'delete',
            }),
            invalidatesTags: ['event'],
        }),
        getEventtypes: builder.query({
            query: () =>({
                url: `api/events/eventtypes`,
                method: 'GET',
            }),
            providesTags: () => ['Eventtypes']
        }),
        getEventDefaultText: builder.query({
            query: (query) =>({
                url: `api/events/defaulttext`,
                method: 'GET',
                params: query.params
            }),
        }),
        editEventPreviewById: builder.mutation({
            query:(data) => ({
                url: `api/events/preview/${data.id}`,
                method: 'put',
                body:{isTrue: data.isTrue}
            }),
        }),

    })
})
  


export const { 
    useGetEventsQuery, 
    useLazyGetEventsQuery, 
    useGetEventByIdQuery, 
    useGetEventByNameQuery,
    useEditEventByIdMutation,
    useAddEventByIdMutation,
    useDeleteEventByIdMutation,
    useGetEventtypesQuery,
    useGetEventDefaultTextQuery,
    useLazyGetEventDefaultTextQuery,
    useEditEventPreviewByIdMutation
} = eventApi