
import { createSlice } from '@reduxjs/toolkit'
import { openSnackbarAction } from '../actions/notifyActions'

const initialState = {
    open: false,
    severity:null,
    vertical:'bottom',
    horizontal:'left',
    message:'Meldung'
}

const notifySlice = createSlice({
    name: 'notify',
    initialState,
    reducers: {        
        openSnackbar: (state , {payload}) => {
            state.severity = payload.severity?payload.severity:initialState.severity
            state.vertical = payload.vertical?payload.vertical:initialState.vertical
            state.horizontal = payload.horizontal?payload.horizontal:'left'
            state.message = payload.message? payload.message : ""
            state.open = true
        },
        closeSnackbar: (state) => {
            state.open = false
        },

    },
    extraReducers: {
        // snackbar
        [openSnackbarAction.pending]: (state) => {

        },
        [openSnackbarAction.fulfilled]: (state, { payload }) => {
            console.log(state)
            state.open = true
        },
        [openSnackbarAction.rejected]: (state, { payload }) => {
    
        }

    },
})
export default notifySlice.reducer

export const { openSnackbar, closeSnackbar } = notifySlice.actions