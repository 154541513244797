import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setTokens, logout } from '../slices/authSlice'
import { openMessagebox } from '../slices/messageSlice'


const baseQuery = async(args, api, extraOptions) => {
    const adjustedArgs = args
    let baseUrl = args.url
    if (args?.params) {
        //if (baseUrl.endsWith('/')) {baseUrl = baseUrl.slice(0,-1)}
        const url = `${baseUrl}?${new URLSearchParams(args.params || {}).toString()}`
        adjustedArgs.url = url
    }
    return baseQueryInternal(adjustedArgs, api, extraOptions)
}
const baseQueryInternal = fetchBaseQuery({
    // base url of backend API
    //baseUrl: 'http://127.0.0.1:5000/',
    //baseUrl: process.env.REACT_APP_BASE_API_URL,
    baseUrl: process.env.REACT_APP_BASE_API_URL,
    // prepareHeaders is used to configure the header of every request and gives access to getState which we use to include the token from the store
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.accessToken
        headers.set('Content-Type', 'application/json')
        headers.set('X-Header-Timeoffset', new Date().getTimezoneOffset())
        if (token) {
            // include token in req header
            headers.set('Authorization', `Bearer ${token}`)
            return headers
        }
    }
})

const baseQueryWithReauth = async(args, api, extraOptions) => {
    let response = await baseQuery(args, api, extraOptions)
    if (response?.error?.status === 401) {
        const options = {
            method: 'post'
        }
        const refreshResult = await baseQuery({
                url: 'api/tokens/',
                method: 'PUT',
                credentials: 'include',
                body: { access_token: api.getState().auth.accessToken, refresh_token: api.getState().auth.refreshToken }
            },
            api, extraOptions)

        if (refreshResult.data) {
            api.dispatch(setTokens(refreshResult.data))
            response = await baseQuery(args, api, extraOptions)
        } else {
            if (localStorage.getItem('accessToken') || localStorage.getItem('refreshToken')){
                api.dispatch(logout())
            }
            
        }

    }

    // Check for form errors and parse them directly
    if (response?.error?.status === 400 && response.error.data?.message?.toUpperCase() == "VALIDATION ERROR") {
        response.error.formErrors = response.error.data.errors.json
        
    } else if (response?.error?.status === 401) {
        const message = {
            severity: 'error',
            vertical: 'top',
            horizontal: 'center',
            message: 'Unauthorized',
            description: 'Fehlende Rechte um auf API zuzugreifen. Bitte melden sie sich an und versuchen es erneut oder wenden sie sich an den Administator um die benötigten Rechte zu erhalten'
        }
        api.dispatch(openMessagebox(message))
    } else if (response?.error?.data != null){
        const message = {
            severity: 'success',
            vertical: 'top',
            horizontal: 'center',
            message: response?.error?.data?.message? response.error.data.message : 'ERROR',
            description: response?.error?.data?.description ? response.error.data.description : 'UNKNOWN ERROR'
        }
        api.dispatch(openMessagebox(message))
    }


    return response
}


const setFormError = (formErrors, formState, setError) => {
    const errors = formErrors
    for (const [key, msg] of Object.entries(errors)) {
        if (formState.defaultValues[key] !== undefined) {
            setError(key, { type: 'custom', message: msg })
        }
    };
    return
}


export { baseQueryWithReauth, setFormError }