import React from 'react';
import { Provider } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import theme from './theme';
import ApiProvider from './contexts/ApiProvider';
import PageLayout from './components/Navbar/PageLayout';
import GlobalSnackbar from './components/Alerts/snackbar';
import GlobalMessagebox from './components/Dialog/dialog';
import store  from './stores/store';


function App() {
  return (
    <Provider store={store}>
      <ApiProvider>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="de">
                <PageLayout />
                <GlobalSnackbar/>
                <GlobalMessagebox/>
          </LocalizationProvider>
        </ThemeProvider>
      </ApiProvider>
</Provider>
  );
}

export default App;