import * as React from 'react';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  
} from '@mui/x-data-grid';
import { DeleteMultipleButton } from './DeleteButton';
import { NewButton } from './EditButton'

export default function CustomToolbar( props ) {
 

  return (
    <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
        <DeleteMultipleButton />
        <NewButton></NewButton>
        {props.buttons?.map((comp, index) => (
          comp(index)
         
          ))
        }
       
    </GridToolbarContainer>
  );
}



