
import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from './ApiBase'

export const locationApi = createApi({
    reducerPath: 'locationApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['location'],
    endpoints: (builder) => ({
        getLocations: builder.query({
            query: () =>({
                url: 'api/locations/',
                method: 'GET',
            }),
            providesTags: () => ['location']
        }),
        getLocationsByType: builder.mutation({
            query: (data) =>({
                url: 'api/locations/eventtypes',
                method: 'POST',
                body: data
            }),
            providesTags: () => ['location']
        }),
        getLocationById: builder.query({
            query: (id) =>({
                url: `api/locations/${id}`,
                method: 'GET',
            }),
            providesTags: () => ['location']
        }),
        getLocationString: builder.query({
            query: (id) =>({
                url: `api/locations/default/${id}`,
                method: 'GET',
            }),
            providesTags: () => ['locationstring']
        }),
        addLocation: builder.mutation({
            query:(data) => ({
                url: `api/locations/`,
                method: 'post',
                body:data
            }),
            invalidatesTags: ['location'],
        }),
        editLocationById: builder.mutation({
            query:(data) => ({
                url: `api/locations/${data.id}`,
                method: 'put',
                body:data
            }),
            invalidatesTags: ['location'],
        }),
        deleteLocationById: builder.mutation({
            query:(id) => ({
                url: `api/locations/${id}`,
                method: 'delete',
            }),
            invalidatesTags: ['location'],
        })
       

    })
})

export const { 
    useGetLocationsQuery, 
    useGetLocationsByTypeMutation, 
    //useLazyGetLocationsByTypeMutation, 
    useGetLocationByIdQuery, 
    useEditLocationByIdMutation,
    useAddLocationMutation,
    useDeleteLocationByIdMutation,
    useGetLocationStringQuery,
    useLazyGetLocationStringQuery
} = locationApi