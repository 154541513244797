
//const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
const BASE_API_URL = process.env.REACT_APP_BASE_API_URL



export default class ApiService {
  constructor() {
    this.base_url =  BASE_API_URL + '/api';
  }

  async request(options) {
    let query = new URLSearchParams(options.query || {}).toString();
    if (query !== '') {
      query = '?' + query;
    }

    let response;
    try {
      response = await fetch(this.base_url + options.url + query, {
        method: options.method,
        headers: {
          'Content-Type': 'application/json',
          ...options.headers,
        },
        body: options.body ? JSON.stringify(options.body) : null,
      });
    }
    catch (error) {
      response = {
        ok: false,
        status: 500,
        json: async () => { return {
          code: 500,
          message: 'The server is unresponsive',
          description: error.toString(),
        }; }
      };
    }

    return {
      ok: response.ok,
      status: response.status,
      body: response.status !== 204 ? await response.json() : null
    };
  }

  async get(url, query, options) {
    return this.request({method: 'GET', url, query, ...options});
  }

  async post(url, body, options) {
    return this.request({method: 'POST', url, body, ...options});
  }

  async put(url, body, options) {
    return this.request({method: 'PUT', url, body, ...options});
  }

  async delete(url, options) {
    return this.request({method: 'DELETE', url, ...options});
  }
}

export function setFormError(response, formState, setError){
  if (response.body?.errors.json ) {
    const errors = response.body.errors.json
    for (const [key, msg] of Object.entries(errors)) {
      if (formState.defaultValues[key]!==undefined){
        setError(key,{ type: 'custom', message: msg })
      }
    };
    return
  }
  console.log('Axio form error')
  if (response.errors?.json) {
    
    const errors = response.errors.json
    console.log(errors)
    for (const [key, msg] of Object.entries(errors)) {
      if (formState.defaultValues[key]!==undefined){
        setError(key,{ type: 'custom', message: msg })
      }
    };
    return
  }
}

export function createOptions(data, labelkey=null, valuekey=null, emptyentry=null){
  let result = []
  if (labelkey === null || valuekey==null){
    return result
  }
  if (emptyentry != null){
    data.push(emptyentry)
  }
  for (var e of data) 
  { 
    if (labelkey in e && valuekey in e ){
      result.push({label:e[labelkey], value:e[valuekey]})
    }
    
  }
  return result
}