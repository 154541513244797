import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice'
import { userApi } from './services/UserService';
import notifyReducer from './slices/notifySlice'
import messageReducer from './slices/messageSlice'
import taskReducer from './slices/taskSlice'
import listenerMiddleware from './middelware/ErrorHandler';
import { teamApi } from './services/TeamService';
import { clubApi } from './services/ClubService';
import { eventApi } from './services/EventService';
import { gcalApi } from './services/GcalService';
import { locationApi } from './services/LocationService';
import { taskApi } from './services/TaskService';
import { reportApi  } from './services/ReportService';
import { stateChangeMiddleware, listnerTask } from './slices/taskSlice';
import { authApi } from './services/AuthService';





const store = configureStore({
  reducer: {
    auth: authReducer,
    notify: notifyReducer,
    message: messageReducer,
    task: taskReducer,
    [userApi.reducerPath]: userApi.reducer,
    [clubApi.reducerPath]: clubApi.reducer,
    [eventApi.reducerPath]: eventApi.reducer,
    [teamApi.reducerPath]: teamApi.reducer,
    [gcalApi.reducerPath]: gcalApi.reducer,
    [locationApi.reducerPath]: locationApi.reducer,
    [taskApi.reducerPath]: taskApi.reducer,
    [reportApi.reducerPath]: reportApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
  },
  devTools:true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      listnerTask.middleware,
      listenerMiddleware.middleware,
      userApi.middleware, 
      teamApi.middleware,
      clubApi.middleware,
      eventApi.middleware,
      gcalApi.middleware,
      locationApi.middleware,
      taskApi.middleware,
      reportApi.middleware,
      authApi.middleware,
      stateChangeMiddleware
      ),
})

export default store
