import { useForm } from "react-hook-form";
import {  useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { FormInputDateTime } from '../../components/FormFields/FormInputDateTime';
import FormPaper from '../../components/FormFields/FormPaper';
import Stack from '@mui/material/Stack';

// MUI
//=============
import Button  from "@mui/material/Button";
import CircularProgress from '@mui/material/CircularProgress';

// Internal
//==================
// Components
//--------------------
import SelectionTeam from "../../components/Sub/SelectionTeams";
import { FormInputDropdown } from '../../components/FormFields/FormInputDropDown';
import { ProgressField } from "../../components/DataGrid/ProgressField";

// Helper
//---------
import { getSearchParam, setSearchParam } from "../../utilities/searchParamHelper";
import { addProgress } from "../../stores/slices/taskSlice";
import store from "../../stores/store";

// Query
//---------
import { useLazyStartTaskQuery } from "../../stores/services/TaskService";
import { useGetReportsQuery, useLazyGetPdfQuery, useLazyGetEditUrlQuery } from "../../stores/services/ReportService";


const defaultValues = {
    start:moment().startOf('h'),
    end:moment({year: 2023, month: 11, day:31,hour:23, minute:59, second:59}),
    report_id: null,
};

const ReportsSchedule = () => {
   
    let start = null
    const methods = useForm({ defaultValues: defaultValues });
    const { handleSubmit, reset, control, setValue, watch, getValues, setError, clearErrors, formState, getFieldState } = methods;
    const watchStart = watch("start");
    const watchEnd = watch("end");
    const startRef = useRef(getValues('start'));
    const endRef = useRef(getValues('end'));
    const teamIdsInit = getSearchParam('teams')? getSearchParam('teams') : []
    const teamIdsRef = useRef();
    const [loadData, setLoadData] = useState(false);
    const [trigger, setTrigger] = useState(false);
    const [teamsGetFinishedState, setTeamsGetFinishedState] = useState(false);
    const [startTaskTrigger, queryTaskResult] = useLazyStartTaskQuery();
    const [startPdfTrigger, queryPdfResult] = useLazyGetPdfQuery();
    const [editTrigger, queryEditResult] = useLazyGetEditUrlQuery();
    const [refetchReports, setRefetchReports] = useState(false);

    useEffect(() => {
        if (teamsGetFinishedState == true)
        {
            setLoadData(true)
        }
        
    }, [teamsGetFinishedState]);
    
    useEffect(() => {
        startRef.current = getValues('start')
      }, [watchStart]);

    useEffect(() => {
        endRef.current = getValues('end')
      }, [watchEnd]);
    

    const startTask = async () => {
        setSearchParam('teams', teamIdsRef.current)
        const q = {params: {
                start: startRef.current.toISOString(),
                end: endRef.current.toISOString(),
                teams: teamIdsRef.current,
                task : 'schedule',
                ident: 'schedule',
                id: 'schedule'
            }
        }
        const { data, isLoading, isError, error, isSuccess } = await startTaskTrigger(q);
    }


    const getPdf = () =>{
        const q = {id:getValues('report_id') }
        startPdfTrigger(q )
    }

    const editReport = () =>{
        const q = {id:getValues('report_id') }
        editTrigger(q )
    }

    useEffect(() => {
        if(queryPdfResult?.isSuccess == true)
        {

           // downloadBlob(queryPdfResult.data.blob)
           let bytes = queryPdfResult.data.bytes
           downloadBlob(
            queryPdfResult.data.bytes,
            queryPdfResult.data.filename,
            queryPdfResult.data.mimetype
            )
        }
      }, [queryPdfResult]);
    
    useEffect(() => {
        if(queryEditResult?.isSuccess == true)
        {
            const url = queryEditResult.data.url 
            // Create a link element
            const link = document.createElement("a");
            window.open(url, '_blank').focus()
            // Set link's href to point to the  URL
            //link.href = url;

            // Append link to the body
            //document.body.appendChild(link);
      
            // Dispatch click event on the link
            // This is necessary as link.click() does not work on the latest firefox
            //link.dispatchEvent(
            //    new MouseEvent('click', { 
            //    bubbles: true, 
            // /   cancelable: true, 
            //    view: window 
            //    })
        //);
      
        // Remove link from body
        //document.body.removeChild(link);
        }
      }, [queryEditResult]);


    const onSuccess = () => {
        setRefetchReports(false)
        setRefetchReports(true)
    }


    function downloadBlob(blobData, name, mimetype) {
        var byteCharacters = atob(blobData);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], { type: mimetype });
        
        // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
        var blobUrl = URL.createObjectURL(blob);
      
        // Create a link element
        const link = document.createElement("a");
      
        // Set link's href to point to the Blob URL
        link.href = blobUrl;
        link.download = name;
      
        // Append link to the body
        document.body.appendChild(link);
      
        // Dispatch click event on the link
        // This is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
          new MouseEvent('click', { 
            bubbles: true, 
            cancelable: true, 
            view: window 
          })
        );
      
        // Remove link from body
        document.body.removeChild(link);
      }
      



    return (
        <>
            <FormPaper>
                <Stack  direction="row"   
                        justifyContent="center"
                        alignItems="center" 
                        spacing={2}>
                    <FormInputDateTime name="start" control={control} label="Start" />
                    <FormInputDateTime name="end" control={control} label="Ende" minDate={start} />
                </Stack>

                <SelectionTeam  
                    teamIdsInit={teamIdsInit}
                    teamIds={teamIdsRef} 
                    queryFinished={setTeamsGetFinishedState}> 
                </SelectionTeam>

            </FormPaper>
            <>
                {loadData==false?
                    <CircularProgress />
                :
                    <FormPaper>
                        <FormInputDropdown 
                            name="report_id" 
                            control={control} 
                            label="Berichte" 
                            optionsQuery={useGetReportsQuery} 
                            optionKey='id' 
                            optionValue='name' 
                            refetch={refetchReports}
                            setRefetch={setRefetchReports}
                            setValue={setValue}
                            setToLast={true}
                                
                            />
                        
                        <ProgressField
                            id='schedule'
                            ident='schedule'
                            showText={true}
                            onSuccess={onSuccess}
                            >
                            <Button onClick={() => {startTask()}} variant={"contained"}>
                                {" "}
                                Terminplan erstellen{" "}
                            </Button>
                        </ProgressField>
                        <Button 
                            disabled={queryPdfResult?.isLoading}
                            startIcon={queryPdfResult?.isLoading ? <CircularProgress size={20} /> : null}
                            onClick={() => {getPdf()}} variant={"contained"}>
                            {" "}
                            Pdf erstellen{" "}
                        </Button>
                        <Button 
                            disabled={queryEditResult?.isLoading}
                            startIcon={queryEditResult?.isLoading ? <CircularProgress size={20} /> : null}
                            onClick={() => {editReport()}} variant={"contained"}>
                            {" "}
                            Bearbeiten{" "}
                        </Button>


                    </FormPaper>

                }
            </>
            
        </>
    )
}

export default ReportsSchedule