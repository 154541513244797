
import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from './ApiBase'

export const gcalApi = createApi({
    reducerPath: 'gcalApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['gcal','scopes'],
    endpoints: (builder) => ({
        getGcals: builder.query({
            query: () =>({
                url: 'api/google/cal',
                method: 'GET',
            }),
            providesTags: () => ['gcal']
        }),
        getScopes: builder.query({
            query: () =>({
                url: 'api/google/scopes',
                method: 'GET',
            }),
            providesTags: () => ['scopes']
        })
    })
})
  


export const { 
    useGetGcalsQuery,
    useGetScopesQuery

} = gcalApi