import React, { useEffect, useState } from "react";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useFormContext, Controller } from "react-hook-form";

export const FormInputDropdown= (
  {name,control, 
    label, 
    options=null, 
    optionsQuery=null, 
    queryParam = null, 
    lazy=false, 
    multiple=false, 
    optionKey=null, 
    optionValue=null, 
    refetch=false, 
    setRefetch=null, 
    setToLast=false,
    setValue=null,
    optionsCurrent=[] 
  }) => {
  // Get options
  const [internOptions, setInternOptions] = useState()
  //let internOptions = null
  //const setInternOptions = (options) => internOptions = options
  let trigger = null
  let query = null
  if (options!=null){
    //setInternOptions(options)
  }
  else if(optionsQuery!=null) {
    if (lazy==true){
      // const [ trigger, { data:result , getError, isLoading, isSuccess}] = optionsQuery? queryParam?optionsQuery(queryParam):optionsQuery() : [null,null, null]
      [trigger, query] = optionsQuery()
    }else{
      query = queryParam?optionsQuery(queryParam) :optionsQuery()
    } 
  }

  useEffect(()=>{
    if (refetch){
      query.refetch()
      setRefetch(false)
    }
    
  },[refetch])
  
  useEffect(() => {
    (async () => {
      let datas = null
      if(options!=null && options!=undefined){
        setInternOptions(options)
        datas = options
      }
      else if (query?.isSuccess==true){
        
        var data = null
        if (query.data?.data == null){
          setInternOptions(query?.data)
          datas = query?.data
        }
        else
        {
          setInternOptions(query?.data?.data)
          datas = query?.data?.data
        }
      }

      // Set last if wanted
      if (setToLast && datas){
        const val = datas?.slice(-1)[0]?.[optionKey]
        if (val){
          setValue(name,val)
        }
         
      }
      

      })()
  }, [query,options]);

  const getInitValues = (value, setFieldValue) => {
    
    if (Array.isArray(value)){
      let result = []
      for (var e of value){
        if (typeof e === 'object'){
          if (optionKey in e){
            result.push(e[optionKey])
          }
        }
        else{
          if (e == null || e == undefined){
            result.push("")
          }
          else{
            result.push(e)
          }
          
        }
       
      }

      
      return result
    }
    let isIn = false
    try{
      isIn  = internOptions?.find(a => a[optionKey] === value)
    } catch {

    }

    

    return (value == null || value == undefined)? multiple? []:"": isIn!=undefined?value:""
  }
 
  const generateSelectOptions = () => {
    
    return internOptions?.map((option) => {
      var text = null
      if (!Array.isArray(optionValue)){
        text = option[optionValue]
      }
      else
      {
        var values = []
        for (var val in optionValue){
          values.push(option[val])
        }
        text = values.join(' - ')
        
      }
      return (
        <MenuItem key={option[optionKey]} value={option[optionKey]}>
          {text}
        </MenuItem>
      );
    });
  };

  const onChangeCustom = (e, onChange) => { 
    if (multiple==true){
      e.target.value==null? e.target.value=[] : e.target.value=e.target.value
    }
    else{
      e.target.value==""? e.target.value=null : e.target.value=e.target.value ;
    }
    onChange(e)
  }
  return <Controller
      control={control}
      name={name}
      render={({ 
        field: { onChange, value, setFieldValue } ,
        fieldState: { error },
        }) => (
        <FormControl >
            <InputLabel>{label}</InputLabel>
            <Select 
                multiple = {multiple}
                onChange={(e) => onChangeCustom(e, onChange)}
                value={getInitValues(value, setFieldValue)}
                helpertext={error ? error.message : null}
                //helperText = 'helper text'
                size="small"
                error={!!error}
                fullWidth
                style={{ minWidth:300}}
                disabled={query?.isLoading}
                
                >
              {!multiple &&
              <MenuItem key={""} value={""}>
                Leer
              </MenuItem>
              
             }
              { Array.isArray(internOptions) && generateSelectOptions()}
            </Select>
        </FormControl>
      )}
    />
};