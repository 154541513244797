

import Box from '@mui/material/Box';
// Internal
import TeamtypeGrid from './Grid';
import { useGetTeamTypesQuery } from '../../../stores/services/TeamService';
import {  useSelector } from 'react-redux'

export default function TeamtypePage() {
    console.log('TeamTypes')
    

    

    return (
        <>
            <Box sx={{ height: 400, width: '100%' }}>      
                <TeamtypeGrid ></TeamtypeGrid>
            </Box>
        </>


    )
}

