
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from './ApiBase'

export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['User'],
    endpoints: (builder) => ({
        getUserDetail: builder.query({
          query: (name) => ({
            url: `api/users/${name}`,
            method: 'GET',
          }),
          providesTags: () => ['User']
        }),
        editUserDetailMutation: builder.mutation({
            query:(detail) => ({
                url: `api/users/${detail.id}`,
                method: 'post',
                body:detail
            }),
            invalidatesTags: ['User'],
        }),
        setGoogleAccessMutation: builder.mutation({
            query:(data) => ({
                url: `api/users/googleaccess`,
                method: 'post',
                body:data
            }),
        })
        }),
      })


export const { 
  useGetUserDetailQuery, 
  useEditUserDetailMutation,
  useSetGoogleAccessMutationMutation
 } = userApi