
import { useForm } from "react-hook-form";
import {   useRef } from 'react';
import {   useGridRootProps } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import FormPaper from '../../components/FormFields/FormPaper';
import {  confirmDialog }  from '../Dialog/ConfirmDialog';
import moment from 'moment';
import { FormInputDateTime } from '../../components/FormFields/FormInputDateTime';
import { taskApi } from '../../stores/services/TaskService';
import store from "../../stores/store";
import { addProgress } from "../../stores/slices/taskSlice";

const defaultValues = {
    id: "",
    summary: "",
    start: moment().startOf('h'),
    end: moment({year: 2023, month: 11, day:31,hour:23, minute:59, second:59})
  };

export function TaskButton( {dialogtitle = 'Starten?', time = true, url = '', task = '' , icon = null, title ='', table=null}  ) {
    const gridProps = useGridRootProps();
    const selectionModel = gridProps.selectionModel

    const methods = useForm({ defaultValues: defaultValues });
    const { handleSubmit, reset, control, setValue, watch, getValues, setError, clearErrors, formState, getFieldState } = methods;
    const [triggerTask, { isLoading, isError, data, error } ] = taskApi.endpoints.startTeamTask.useLazyQuery()
    const [triggerStatus, resultStatus ] = taskApi.endpoints.getTaskStatus.useLazyQuery()
    let oldDate = useRef()
    let start = null

    const openDialog = () => {
        const content = 
        <FormPaper>
            <FormInputDateTime name="start" control={control} label="Start" />
            <FormInputDateTime name="end" control={control} label="Ende" minDate={start} depend={oldDate.current} />
        </FormPaper>
      
        const dialogTitle = title
        confirmDialog(dialogTitle,content,startSync)
    }

    const startSync = () => {
        const url = window.location.href       
        selectionModel.forEach(async (id, index) => {
            const d = {}
            d.task = task
            d.id = id
            d.url = url
            d.start= getValues('start').toISOString()
            d.end= getValues('end').toISOString()
            const { data, isLoading, isError, error, isSuccess } = await triggerTask(d);
            if (isSuccess==true){
                store.dispatch(addProgress({
                    id:id, 
                    ident:table,
                    task:task,
                    url:data.url
                }
                ))

            }

        })
    }
    

    return (
        <Button
            key={task}
            variant="text"
            onClick={() => openDialog()}
        >
             {icon} {title}
        </Button>
    );
}
