

import Box from '@mui/material/Box';
// Internal
import TeamGrid from './Grid';


const TeamsPage = () => {


    return (
        <>
            <Box sx={{ height: 400, width: '100%' }}>      
                <TeamGrid ></TeamGrid>
            </Box>
        </>


    )
}

export default TeamsPage