
// Internal
//======================
// UI
//-------------
import { DeleteButton } from '../../components/DataGrid/DeleteButton';
import { EditButton } from '../../components/DataGrid/EditButton';
import CustomDataGrid from '../../components/DataGrid/DataGrid';
// Query
//--------------
import { useGetClubsQuery, useDeleteClubByIdMutation } from '../../stores/services/ClubService'; 


const ClubGrid = () => {
    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 0,
            align: 'center'
        },
        {
            field: 'home',
            headerName: 'Heim',
            type: 'boolean',
            flex: 0,
            align: 'center',
            valueGetter: (params) => {
                return params.row.home
            }

        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            align: 'left'
        },
        {
            field: 'actions',
            type: 'actions',
            align: 'left',
            getActions: (params) =>{return [
                <DeleteButton params={params.row} ></DeleteButton>,
                <EditButton params={params.row}></EditButton>
        ]}
        },



    ];

    return (
        <>
            <CustomDataGrid
                    columns = {columns}
                    getQuery={useGetClubsQuery}
                    deleteQuery={useDeleteClubByIdMutation}
                />
      </>
    )
}

export default ClubGrid