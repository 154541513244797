import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useForm, useWatch } from "react-hook-form";
import { useParams, useNavigate } from 'react-router-dom';

// MUI
//-----------------------
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton'; 
import CircularProgress from '@mui/material/CircularProgress';
import InfoIcon from '@mui/icons-material/Info';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography  from '@mui/material/Typography';
import RefreshIcon from '@mui/icons-material/Refresh';



// Internal
//------------------
import FormPaper from '../../components/FormFields/FormPaper';
import { FormInputText } from '../../components/FormFields/FormInputText';
import { FormInputDateTime } from '../../components/FormFields/FormInputDateTime';
import { FormInputDropdown } from '../../components/FormFields/FormInputDropDown';
import { FormInputCheckbox } from '../../components/FormFields/FormInputCheckbox';
import { useEditEventPreviewByIdMutation, useGetEventByIdQuery, useEditEventByIdMutation, useAddEventByIdMutation, useGetEventtypesQuery, useLazyGetEventDefaultTextQuery } from '../../stores/services/EventService';
import { useGetTeamsHomeQuery } from '../../stores/services/TeamService'
import { useGetLocationsByTypeMutation, useGetLocationsQuery } from '../../stores/services/LocationService';
import { useLazyGetLocationStringQuery } from '../../stores/services/LocationService';
import moment from 'moment';
import SubmitButton from '../../components/DataGrid/SubmitButton';


const defaultValues = {
  id: null,
  summary: "",
  start: moment().startOf('h'),
  end: moment(moment().startOf('h').add(1, 'hour')),
  team_id: null,
  eventtype_id: null,
  location: null,
  location_id: null,
  location_string : "",
  fullday: false,
  pending: null,
  original: null
};


export default function EventEditPage() {
  const params = useParams();
  const form = useForm({ defaultValues: defaultValues });
  const { handleSubmit, reset, control, setValue, watch, getValues, setError, clearErrors, formState, getFieldState } = form;
  const { data , getError, isLoading, isSuccess } = useGetEventByIdQuery(params.id,{skip: params.id=='new'});
  const [ textDefaultTrigger, defaultTextQuery] = useLazyGetEventDefaultTextQuery()
  const [ locationsTrigger, locationsQuery]  = useGetLocationsByTypeMutation()
  const { data:allLocationsData , getError:allLocationsGetError, isLoading:isLoadingAllLocations, isSuccess:isSuccessAllLocations }  = useGetLocationsQuery()
  const [ locationtextTrigger, locationtextQuery] = useLazyGetLocationStringQuery()
  const [ locations, setLocations] = React.useState()
  const [ allLocations, setAllLocations] = React.useState()
  const [ fullday, setFullday] = React.useState()
  const [ minDate, setMinDate] = React.useState()
  const [ loaded, setLoaded] = React.useState(false)
  const dataRef = React.useRef()
  dataRef.current = data
  const watchDefaultText = useWatch({control, name:['location_id','eventtype_id']})


  const [ currentData, setCurrentData] = React.useState()
  const [ originalData, setOriginalData] = React.useState()
  
  useEffect(() => {
    (async () => {
      if (data){
        setCurrentData(data?.pending? data?.pending :data)

        return
      }
      if (params.id=='new'){
        setCurrentData(defaultValues)
        
        return
      }
    })();
  }, [data]);

  useEffect(() => {
    (async () => {
      if (currentData){
        reset(currentData)
        setFullday(currentData.fullday)
        setMinDate(currentData.start)
        if (currentData?.eventtype_id != null){
          const d = {
            event_id: currentData.id,
            type_id: currentData.eventtype_id
          }
          locationsTrigger(d)
        }
        setLoaded(true)
      } 
    })();
  }, [currentData]);

  useEffect(()=> {
    const eventtype_id = getValues('eventtype_id')
    if (eventtype_id!=null && eventtype_id!=undefined){
      const d = {
        event_id: currentData.id?currentData.id : -1 ,
        type_id: eventtype_id
      }
      locationsTrigger(d)
    }
  }, [watch('eventtype_id')])

  useEffect(()=> {
    if (locationsQuery.isSuccess==true){
      setLocations(locationsQuery.data)
    }
  }, [locationsQuery]) 

  useEffect(()=> {
    if (isSuccessAllLocations == true){
      setAllLocations(allLocationsData)
    }
  }, [isSuccessAllLocations]) 


  useEffect(()=>{
    const id = getValues('location_id')
    if (currentData && currentData?.location_id == id){
      setValue('location_string', currentData.location_string)
    }
    else if (id!=null && id!=undefined){
      locationtextTrigger(id)
    }
    else{
      setValue('location_string', "")
    }
  },[watch('location_id')]
  )


  useEffect(()=>{
    if (locationtextQuery.isSuccess==true){
      setValue('location_string', locationtextQuery.data.text)
    }
  },[locationtextQuery]
  )



  useEffect(()=>{
    const type  = watchDefaultText[1]
    const location = watchDefaultText[0]
    if (!location || !type  ){ return }
    if (location != currentData?.location_id || type != currentData?.eventtype_id){
      executeDefaultTextTrigger() 
    } else {
      setValue('summary', currentData.summary)
    }
  },[watchDefaultText]
  )

  const executeDefaultTextTrigger = (type=null, location=null) => { 
    if (!type){type = getValues('eventtype_id')}
    if (!location){location = getValues('location_id')}
    if (!type || !location){return}
    const params = {eventtype_id:type, location_id:location}
    const query ={params:params}
    textDefaultTrigger(query)

  }

  useEffect(()=>{
    if (defaultTextQuery.isSuccess==true){
      setValue('summary', defaultTextQuery.data.text)
    }
  },[defaultTextQuery]
  )


  useEffect(()=> {
    const fullday = getValues('fullday')
    setFullday(fullday)
  }, [watch('fullday')])

  useEffect(()=> {
    setMinDate(getValues('start'))
  }, [watch('start')])

  const checkPending = (property) => {  
    try{
      if (currentData){
        let text = ""
        if (form.getValues(property) != data[property]){
          if (property == 'location_id'){
            text = data?.location?.district  ??  ''
          }
  
          text = data[property]
          return (
            <React.Fragment>
              <Typography color="inherit" fontSize={24} >Original Daten</Typography>
              <Typography color="inherit" fontSize={14} >{text}</Typography>
              
              <Button variant={"contained"} onClick={() => setValue(property,data[property] ) }> Rücksetzen </Button>
            </React.Fragment>
          )
  
        }
      }
    }
    catch{

    }
    

  }

  useEffect(()=> {
      }, [watch()])

  return (
    <>
      <FormPaper>
        <>
          {isSuccess !== true && params.id!=='new' ?
            <CircularProgress />
            :
            <>
              <Stack direction="row" spacing={2}>
                <FormInputText name="summary" control={control} label="Text" />
                {checkPending("summary") &&
                  <Tooltip title={checkPending("summary")}>
                    <InfoIcon />
                  </Tooltip>
                }
                <IconButton onClick={() => executeDefaultTextTrigger()} ><RefreshIcon /></IconButton>
              </Stack>
              <Stack direction="row" spacing={2}>
                <FormInputDateTime name="start" control={control} label="Start" shift={{get: getValues, set: setValue, field: 'end'}} time={!fullday}/>
                {checkPending("start") &&
                  <Tooltip title={checkPending("start")}>
                    <InfoIcon />
                  </Tooltip>
                }
              </Stack>
              <Stack direction="row" spacing={2}>
                <FormInputDateTime name="end" control={control} label="Ende" minDate={minDate}  time={!fullday}/>
                {checkPending("end") &&
                  <Tooltip title={checkPending("end")}>
                    <InfoIcon />
                  </Tooltip>
                }
              </Stack>
              <FormInputCheckbox name="fullday" control={control} label="Tagesevent" />
              <Stack direction="row" spacing={2}>
                <FormInputText name="location_string" control={control} label="Ortstext"/>
                {checkPending("location_string") &&
                  <Tooltip title={checkPending("location_string")}>
                    <InfoIcon />
                  </Tooltip>
                }
              </Stack>
              <FormInputDropdown name="team_id" control={control} label="Mannschaft" optionsQuery={useGetTeamsHomeQuery} optionKey='id' optionValue='name' />
              <FormInputDropdown name="eventtype_id" control={control} label="Typ" optionsQuery={useGetEventtypesQuery} optionKey='id' optionValue='text' />
              <Stack direction="row" spacing={2}>
                <FormInputDropdown name="location_id" control={control} label="Ort" options={allLocations}  optionKey='id' optionValue='shortText' />
                {checkPending("location_id") &&
                  <Tooltip title={checkPending("location_id")}>
                    <InfoIcon />
                  </Tooltip>
                }
              </Stack>


              <SubmitButton
                form = {form}
                queryAdd= {useAddEventByIdMutation}
                queryEdit= {useEditEventByIdMutation}
              >
              </SubmitButton>
            </>
          }
        </>
      </FormPaper>

    </>
  );
}