

import Box from '@mui/material/Box';
// Internal
import ClubGrid from './ClubGrid';
const ClubPage = () => {
    return (
        <>
            <Box sx={{ height: 400, width: '100%' }}>            
                <ClubGrid ></ClubGrid>
            </Box>
        </>
    )
}

export default ClubPage