import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { create } from 'zustand';
import DOMPurify from 'isomorphic-dompurify';


const useSuccessDialogStore = create((set)=>({
    title:"",
    content:<></>,
    onSubmit:undefined,
    close:()=>
        set({
            onSubmit:undefined
        })

}))

export const successDialog = (title, content, onSubmit, error=false ) => {
    useSuccessDialogStore.setState({
        title, 
        content, 
        onSubmit,
        error,
    })
    
}

export function SuccessDialog() {
    const { title, content, onSubmit, close, error} = useSuccessDialogStore()
    const data = `lorem <b onmouseover="alert('mouseover');">ipsum</b>`
    const clean = DOMPurify.sanitize(data);
  return (
        
      <Dialog
        maxWidth="sm"
        fullWidth
        
        open={Boolean(onSubmit)}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
            {title}        
        </DialogTitle>

        <DialogContent dividers>
          <DialogContentText>
          
          {content}
  


          </DialogContentText>
          
        </DialogContent>
        <DialogActions>
        <Button 
            color= {error==false ? "primary" : "error"}
            variant='contained'
            onClick={() => {
                if (onSubmit){
                    onSubmit()
                    };
                close()
            }}
                autoFocus>
            Ok
          </Button>

        </DialogActions>
      </Dialog>
  );
}