import DnsIcon from '@mui/icons-material/Dns';
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import LocationOnIcon from '@mui/icons-material/LocationOn';

export const mainNavbarItems = [
    {
        id: 1,
        icon: <HomeIcon />,
        label: 'Vereine',
        route: 'clubs',
    },
    {
        id: 2,
        icon: <GroupIcon />,
        label: 'Mannschaften',
        route: 'teams',
        children: [
            {
                id: 4,
                icon: <GroupIcon />,
                label: 'Teamgruppen',
                route: 'teams/groups',
            },
            {
                id: 5,
                icon: <GroupIcon />,
                label: 'Teamtypen',
                route: 'teams/types',
            },
        
        ]
    },
    {
        id: 3,
        icon: <GroupIcon />,
        label: 'Events',
        route: 'events',
    },
    {
        id: 6,
        icon: <GroupIcon />,
        label: 'Orte',
        route: 'locations',
    },
    {
        id: 7,
        icon: <GroupIcon />,
        label: 'Reports',
        route: 'reports',
        children: [
            {
                id: 8,
                icon: <GroupIcon />,
                label: 'Terminplan',
                route: 'reports/schedule',
            }
        ]
    },

    
]