
import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from './ApiBase'
import { addProgress } from "../../stores/slices/taskSlice";


import {
    createAction,
  } from '@reduxjs/toolkit'

  const notificationsReceived = createAction(
    'taskApi/notificationsReceived'
  )

export const taskApi = createApi({
    reducerPath: 'taskApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['task', 'status'],
    endpoints: (builder) => ({
        startTeamTask: builder.query({
            query: (data) =>({
                url: `api/teams/task/${data.task}`,
                method: 'POST',
                body:data
            }),              
            providesTags: (result, error, arg) =>{ return [
                { type: 'task', id: { id: arg.id, url:arg.url } }
              ]}
        }),      
        getTaskStatus:   builder.query({ 
            query: (url) =>({
                url: url,
                method: 'GET'
            }),
            transformResponse: (response , meta, arg) => { 
              return response},         
        }),
        startTask: builder.query({
          query: (query) =>({
            url: `api/tasks/start`,
            method: 'GET',
            params: query?.params
        }),
        async onQueryStarted(id, { dispatch, queryFulfilled }) {
          const {data, meta}  = await queryFulfilled
          let params = new URL(meta.request.url).searchParams;
          const q = {
            id:params.get('id'), 
            ident:params.get('ident'),
            task:params.get('task'),
            url:data.url
          };
          dispatch(addProgress({
            id:q.id, 
            ident:q.ident,
            task:q.task,
            url:q.url
          }))
       
          // `onSuccess` side-effect
       
        }
        })
    })
})
  


export const { 
    useStartTeamTaskQuery,
    useGetTeamStatusQuery,
    useLazyStartTaskQuery 
} = taskApi

