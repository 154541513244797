import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { create } from 'zustand';


const useConfirmDialogStore = create((set)=>({
    title:"",
    content:<></>,
    onSubmit:undefined,
    close:()=>
        set({
            onSubmit:undefined
        })

}))

export const confirmDialog = (title, content, onSubmit ) => {
    useConfirmDialogStore.setState({
        title, 
        content, 
        onSubmit,
    })
    
}

export function ConfirmDialog() {
    const { title, content, onSubmit, close} = useConfirmDialogStore()
  return (
        
      <Dialog
        maxWidth="sm"
        fullWidth
        open={Boolean(onSubmit)}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
            { title } 
        </DialogTitle>

        <DialogContent dividers>
          { content }
        </DialogContent>
        <DialogActions>
        <Button 
            color="primary" 
            variant='contained'
            onClick={() => {
                if (onSubmit){
                    onSubmit()
                    };
                close()
            }}
                autoFocus>

            
            Ok
          </Button>
          <Button 
            color="secondary"  
            variant='contained'
            onClick={()=>close()}>Abort</Button>

        </DialogActions>
      </Dialog>
  );
}