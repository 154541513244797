import CircularProgress from '@mui/material/CircularProgress';
import CircularProgressWithLabel from '../CircualrProgressWithLabel';
import { SuccessDialog, successDialog } from '../Dialog/SuccessDialog';
import { ErrorDialog, errorDialog } from '../Dialog/ErrorDialog';
import  Button  from '@mui/material/Button';
import {  useEffect, useState } from 'react';
import store from "../../stores/store";
import { useSelector } from "react-redux";
import { clearProgress } from "../../stores/slices/taskSlice";
import Stack  from '@mui/material/Stack';
import Typography  from '@mui/material/Typography';
import { taskApi } from '../../stores/services/TaskService';


export const ProgressField = (props) => {
    const ident = props.ident
    const showText = props.showText
    const progress = useSelector(state => state.task.progress[ident]?.[props.id])
    const [currentProgress, setCurrentProgress] = useState(progress)
    const onSuccess = props.onSuccess
    useEffect(() => {
        if (progress){
            setCurrentProgress(progress)
            if (progress?.status == 3 && onSuccess){
                onSuccess();
            }
        }
    }, [progress])
    
    const onSubmit = () => {
        
        const payload =  {
            id:props.id,
            ident:ident,
            }
        store.dispatch(clearProgress(payload))

    }

    const renderProgressField = (param) => {
        switch (param) {
            case 0:
                return (
                    <>
                       <CircularProgress />
                    </>
                )
            case 1:
                return (
                    <Stack direction="row" spacing={2} alignContent={'center'} alignItems={'center'}>
                            <CircularProgressWithLabel value={progress.percentage} />
                            {
                                showText &&
                                <Typography align='center' alignContent={'center'}>
                                    <span dangerouslySetInnerHTML={{__html: progress.msg}} />
                                </Typography>
                            }
                    </Stack>
                        
                   
                )
            case 2:
                return (
                    <>
                    <Button variant='contained' color='error' 
                        onClick={() => errorDialog('Task fehlerhaft', <span dangerouslySetInnerHTML={{__html: progress.msg}} />, onSubmit)}
                        > 
                        Fehler 
                    </Button>
                    <ErrorDialog></ErrorDialog>
                </>
                )
            case 3:
                    return (
                        <>
                            <Button variant='contained' 
                            onClick={() => 
                                successDialog('Task erfolgreich abgeschlossen',  <span dangerouslySetInnerHTML={{__html: progress.msg}} /> , onSubmit)}
                                > 
                                Erfolg 
                            </Button>
                            <SuccessDialog></SuccessDialog>
                        </>
                    )
            default:
                return (
                    <>
                        {props.children}   
              
                    </>
                )
        }
    }

    return (
        <>  
            {renderProgressField(progress?.status)}
        </>
    )
  };


