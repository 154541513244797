
import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from './ApiBase'


export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        register: builder.mutation({
            query: (query) =>({
                url: `/api/users/`,
                method: 'POST',
                body:query.data,
                param: query.param
            }),              
        }),  
        login : builder.mutation({
            query: (query) =>({
                url: `/api/tokens/`,
                method: 'POST',
                body:query.data,
                param: query.param,
                prepareHeaders: (headers) => {
                    headers.set('Authorization', 'Basic  ' +  btoa(query.data.email + ":" + query.data.password))
                    return headers
                }
                })
            }),              
        }),      
        })

  


export const { 
    useLoginMutation,
    useRegisterMutation,
} = authApi

