import { Navigate } from 'react-router-dom';
import {
    createBrowserRouter,
  } from "react-router-dom";

import LoginPage from '../../../pages/Login/LoginPage';
import ProfilePage from '../../../pages/Login/ProfilePage';
import RegisterPage from '../../../pages/Login/RegisterPage';

import ClubPage from '../../../pages/Clubs/Clubs';
import ClubEdit from '../../../pages/Clubs/ClubEdit';

import TeamsPage from '../../../pages/Teams/Teams/Page';
import TeamEditPage from '../../../pages/Teams/Teams/Edit';

import TeamgroupsPage from '../../../pages/Teams/Groups/Page';
import TeamgroupEditPage from '../../../pages/Teams/Groups/Edit';

import TeamgtypesPage from '../../../pages/Teams/Types/Page';
import TeamtypeEditPage from '../../../pages/Teams/Types/Edit';

import EventsPage from '../../../pages/Events/Events';
import EventEditPage from '../../../pages/Events/EventEdit';

import LocationsPage from '../../../pages/Locations/Locations/Page';
import LocationEditPage from '../../../pages/Locations/Locations/Edit';

import ReportsSchedule from '../../../pages/Reports/Schedule';


import App from '../../../App';

 const routes =  [
    {
        path:"/",
        element:<App />,
        children:[
            {
                path:"login",
                element:<LoginPage />
            },
            {
                path:"register",
                element:<RegisterPage />
            },
            {
                path:"user/:username",
                element:<ProfilePage />
            },
            {
                label:"Vereine",
                path:"clubs",
                element:<ClubPage />,
            },
            {
                path:"clubs/:id",
                element:<ClubEdit />
            },
            {
                path:"teams",
                element:<TeamsPage />,
            },
            {
                path:"teams/:id",
                element:<TeamEditPage />
            },
            {
                path:"teams/groups",
                element:<TeamgroupsPage />,
            },
            {
                path:"teams/groups/:id",
                element:<TeamgroupEditPage />
            },
            {
                path:"teams/types",
                element:<TeamgtypesPage />,
            },
            {
                path:"teams/types/:id",
                element:<TeamtypeEditPage />
            },
            {
                path:"events",
                element:<EventsPage />,
            },
            {
                path:"events/:id",
                element:<EventEditPage />
            },
            {
                path:"locations",
                element:<LocationsPage />,
            },
            {
                path:"locations/:id",
                element:<LocationEditPage />
            },
            {
                path:"reports/schedule",
                element:<ReportsSchedule />
            }
        ]},
{
    path:"*",
    element:<Navigate to="/" />,
}
]

const router = createBrowserRouter(routes)

export default router

export { routes }

