

import Box from '@mui/material/Box';
// Internal
import TeamgroupGrid from './Grid';


const TeamgroupsPage = () => {


    return (
        <>
            <Box sx={{ height: 400, width: '100%' }}>      
                <TeamgroupGrid ></TeamgroupGrid>
            </Box>
        </>


    )
}

export default TeamgroupsPage